<template>
    <router-link :to="to" class="rev-fw-button" :class="{dark: $store.state.settings.darkMode}">
        <img :src="icon"/>
    </router-link>
</template>

<script>
    export default {
        name: 'RevFwButton',

        props: [
            'to',
            'direction'
        ],

        computed: {
            icon() {
                switch(this.direction){
                    case 'fw':
                        return require('@/assets/fw.svg')
                    case 'rev':
                        return require('@/assets/rev.svg')
                    case 'back':
                        return require('@/assets/back.svg')
                }
                return require('@/assets/rev.svg')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .rev-fw-button {
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 46px;
        @include transition;
        opacity: .35;

        @include breakpoint('mobile') {
            opacity: 1;
        }

        img {
            height: 32px;
        }

        &:hover {
            transform: scale(1.1);
            opacity: 1;
        }

        @include mobileClick;

        &.dark {
            img {
                filter: invert(1);
            }
        }
    }
</style>
