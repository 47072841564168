import Vue from 'vue'
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client/core'
import VueApollo from 'vue-apollo'
import gql from 'graphql-tag'
import { config } from '@/config'

import possibleTypes from '@/possibleTypes.json'

import YoutubeVideo from '@/components/players/YoutubeVideo'
import BandcampPlayer from '@/components/players/BandcampPlayer'
import SoundcloudPlayer from '@/components/players/SoundcloudPlayer'
import SpotifyPlayer from '@/components/players/SpotifyPlayer'

const cache = new InMemoryCache({ possibleTypes })
const httpLink = createHttpLink({ uri: `${config.baseCms}/graphql` });

const strapiClient = new ApolloClient({
  cache,
  link: httpLink
})

const pubState = config.showDrafts ? 'PREVIEW' : 'LIVE'

export const strapiProvider = new VueApollo({
    defaultClient: strapiClient
})

Vue.use(VueApollo)

export const artistRoutesQuery = gql` query artistRoutes {
    artists(
        publicationState: ${pubState},
        sort: "title:asc"
    ){
        data {
            id
            attributes {
                url_key
            }
        }
    }
}`

export const artistsQuery = gql` query artists($limit: Int!) {
    artists(
        publicationState: ${pubState},
        sort: "title:asc",
        pagination: {
            limit: $limit
        }
    ){
        data {
            id
            attributes {
                title
                image {
                    data {
                        attributes {
                            url
                            formats
                            width
                            height
                        }
                    }
                }
                url_key
            }
        }
    }
}`

export const artistQuery = gql` query artist($url_key: String!) {
    artists(
        publicationState: ${pubState},
        pagination: {
            limit: 1
        },
        filters: {
            url_key: {
                eq: $url_key
            }
        }
    ){
        data {
            id
            attributes {
                title
                url_key
                bio
                image {
                    data {
                        attributes {
                            url
                            formats
                            width
                            height
                        }
                    }
                }
                gallery {
                    data {
                        attributes {
                            url
                            formats
                            width
                            height
                        }
                    }
                }
                bookable
                links {
                    url
                    type
                }
                videos {
                    ... on ComponentGeneralYoutubeVideo {
                        title
                        youtube_video_id
                    }
                }
                artist_connections {
                    artist {
                        data {
                            id
                            attributes {
                                title
                                url_key
                                image {
                                    data {
                                        attributes {
                                            url
                                            formats
                                            width
                                            height
                                        }
                                    }
                                }
                            }
                        }
                    }
                    contributions {
                        type
                    }
                }
            }
        }
    }
}`

export const releaseRoutesQuery = gql` query releaseRoutes {
    releases(
        publicationState: ${pubState},
        sort: "release_date:desc"
    ){
        data {
            id
            attributes {
                url_key
            }
        }
    }
}`

export const releasesQuery = gql` query releases($limit: Int!) {
    releases(
        publicationState: ${pubState},
        sort: "release_date:desc",
        pagination: {
            limit: $limit
        }
    ){
        data {
            id
            attributes {
                title
                artist
                url_key
                cover {
                    data {
                        attributes {
                            url
                            formats
                            width
                            height
                        }
                    }
                }
            }
        }
    }
}`

export const releaseQuery = gql` query release($url_key: String!) {
    releases(
        publicationState: ${pubState},
        pagination: {
            limit: 1
        },
        filters: {
            url_key: {
                eq: $url_key
            }
        }
    ){
        data {
            id
            attributes {
                title
                artist
                url_key
                description
                release_date
                cover {
                    data {
                        attributes {
                            url
                            formats
                            width
                            height
                        }
                    }
                }
                gallery {
                    data {
                        attributes {
                            url
                            formats
                            width
                            height
                        }
                    }
                }
                players {
                    ... on ComponentGeneralSpotifyPlayer {
                        spotify_album_id
                    }
                    ... on ComponentGeneralBandcampPlayer {
                        bandcamp_album_id
                    }
                    ... on ComponentGeneralSoundcloudPlayer {
                        soundcloud_player_url
                    }
                }
                links {
                    url
                    type
                }
                videos {
                    ... on ComponentGeneralYoutubeVideo {
                        title
                        youtube_video_id
                    }
                }
                track_list {
                    side
                    track {
                        number
                        title
                    }
                }
                artist_connections {
                    artist {
                        data {
                            id
                            attributes {
                                title
                                url_key
                                image {
                                    data {
                                        attributes {
                                            url
                                            formats
                                            width
                                            height
                                        }
                                    }
                                }
                            }
                        }
                    }
                    contributions {
                        type
                    }
                }
                catalog_numbers {
                    number
                    type
                }
                products {
                    shop_handle
                }
            }
        }
    }
}`

export const connectedReleasesQuery = gql` query($artistSlug: String!){
    releases(
        publicationState: ${pubState},
        sort: "release_date:desc",
        filters: {
            artist_connections: {
                artist: {
                    url_key: {
                        eq: $artistSlug
                    }
                }
            }
        }
    ){
        data {
            id
            attributes {
                title
                artist
                url_key
                release_date
                cover {
                    data {
                        attributes {
                            url
                            formats
                            width
                            height
                        }
                    }
                }
                artist_connections (
                    filters: {
                        artist: {
                            url_key: {
                                eq: $artistSlug
                            }
                        }
                    }
                ){
                    contributions {
                        type
                    }
                }
            }
        }
    }
}`

export const connectedBandReleasesQuery = gql` query($artistSlug: String!){
    releases(
        publicationState: ${pubState},
        sort: "release_date:desc",
        filters: {
            artist_connections: {
                artist: {
                    artist_connections: {
                        artist: {
                            url_key: {
                                eq: $artistSlug
                            }
                        }
                    }
                }
            }
        }
    ){
        data {
            id
            attributes {
                title
                artist
                url_key
                release_date
                cover {
                    data {
                        attributes {
                            url
                            formats
                            width
                            height
                        }
                    }
                }
                artist_connections {
                    artist {
                        data {
                            attributes {
                                artist_connections {
                                    contributions {
                                        type
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}`

export const connectedArtistsQuery = gql` query($artistSlug: String!){
    artists(
        publicationState: ${pubState},
        sort: "title:asc",
        filters: {
            artist_connections: {
                artist: {
                    url_key: {
                        eq: $artistSlug
                    }
                }
            }
        }
    ){
        data {
            id
            attributes {
                title
                url_key
                image {
                    data {
                        attributes {
                            url
                            formats
                            width
                            height
                        }
                    }
                }
                artist_connections {
                    contributions {
                        type
                    }
                }
            }
        }
    }
}`

export const connectedEventsQuery = gql` query($artistSlug: String!, $date: Date!){
    events(
        publicationState: ${pubState},
        filters: {
            date: {
                gte: $date
            }
            artists: {
                url_key: {
                    eq: $artistSlug
                }
            }
        },
        sort: "date:asc"
    ){
        data {
            id
            attributes {
                title
                description
                url_key
                country
                city
                venue
                date
                start_time
                end_time
                flyer {
                    data {
                        attributes {
                            url
                            width
                            height
                            formats
                        }
                    }
                }
                artists {
                    data {
                        id
                        attributes {
                            title
                            url_key
                            image {
                                data {
                                    attributes {
                                        url
                                        formats
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}`

export const eventsQuery = gql` query($date: Date!, $limit: Int!){
    events(
        publicationState: ${pubState},
        pagination: {
            limit: $limit
        },
        filters: {
            date: {
                gte: $date
            }
        },
        sort: "date:asc"
    ){
        data {
            id
            attributes {
                title
                description
                url_key
                country
                city
                venue
                date
                start_time
                end_time
                flyer {
                    data {
                        attributes {
                            url
                            width
                            height
                            formats
                        }
                    }
                }
                artists {
                    data {
                        id
                        attributes {
                            title
                            url_key
                            image {
                                data {
                                    attributes {
                                        url
                                        formats
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}`

export const slidesQuery = gql` query {
    slides(
        publicationState: ${pubState},
        sort: "sort:asc"
    ){
        data {
            id
            attributes {
                image {
                    data {
                        attributes {
                            url
                            width
                            height
                            formats
                        }
                    }
                }
                mobileImage {
                    data {
                        attributes {
                            url
                            width
                            height
                            formats
                        }
                    }
                }
                link
                sort
                invert
            }
        }
    }
}`

export const imprintQuery = gql` query {
    imprint {
        data {
            attributes {
                title
                content
                updatedAt
            }
        }
    }
}`

export const privacyPolicyQuery = gql` query {
    privacyPolicy {
        data {
            attributes {
                title
                content
                updatedAt
            }
        }
    }
}`

export const aboutQuery = gql` query {
    aboutUs {
        data {
            attributes {
                title
                content
            }
        }
    }
}`

export const contactQuery = gql` query {
    contact {
        data {
            attributes {
                title
                content
            }
        }
    }
}`

export const agbQuery = gql` query {
    agb {
        data {
            attributes {
                title
                content
            }
        }
    }
}`

export const cancellationQuery = gql` query {
    cancellation {
        data {
            attributes {
                title
                content
            }
        }
    }
}`

export const settingsQuery = gql` query {
    setting {
        data {
            attributes {
                page_description
            }
        }
    }
}`

Vue.prototype.$getImageUrl = function(url) {
    return `${config.baseCms}${url}`
}

Vue.prototype.$getArtistImage = function(artist, format = 'medium') {
    if(artist.attributes.image.data && artist.attributes.image.data.attributes){
        var image = artist.attributes.image.data.attributes
        if(image.formats && image.formats[format]){
            return this.$getImageUrl(image.formats[format].url)
        }
        else {
            return this.$getImageUrl(image.url)
        }
    } else {
        return require('@/assets/blank-artist.png')
    }
}

Vue.prototype.$getReleaseImage = function(release, format = 'medium') {
    if(release.attributes.cover.data && release.attributes.cover.data.attributes){
        var cover = release.attributes.cover.data.attributes
        if(cover.formats && cover.formats[format]){
            return this.$getImageUrl(cover.formats[format].url)
        }
        else {
            return this.$getImageUrl(cover.url)
        }
    } else {
        return require('@/assets/blank-release.png')
    }
}

Vue.prototype.$getImageFormatUrl = function(image, format) {
    if(image.data && image.data.attributes){
        if(image.data.attributes.formats && image.data.attributes.formats[format]){
            return this.$getImageUrl(image.data.attributes.formats[format].url)
        }
        else {
            return this.$getImageUrl(image.data.attributes.url)
        }
    }
}

Vue.prototype.$getPlayer = function(player) {
    switch(player.__typename){
        case 'ComponentGeneralBandcampPlayer':
            return {
                component: BandcampPlayer,
                props: {
                    bandcamp_album_id: player.bandcamp_album_id
                }
            }
        case 'ComponentGeneralSoundcloudPlayer':
            console.log(player)
            return {
                component: SoundcloudPlayer,
                props: {
                    soundcloud_url: player.soundcloud_player_url
                }
            }
        case 'ComponentGeneralSpotifyPlayer':
            return {
                component: SpotifyPlayer,
                props: {
                    spotify_album_id: player.spotify_album_id
                }
            }
    }
}

Vue.prototype.$getVideo = function(video) {
    switch(video.__typename){
        case 'ComponentGeneralYoutubeVideo':
            return {
                component: YoutubeVideo,
                props: {
                    videoId: video.youtube_video_id,
                    title: video.title
                }
            }
    }
}

Vue.prototype.$getArtistContributions = function(contributions) {
    var string = ''
    var resolveType = function(type) {
        switch(type){
            case 'vocals':
                return 'Vocals'
            case 'beats':
                return 'Beats'
            case 'scratches':
                return 'Scratches'
            case 'instruments':
                return 'Instrumente'
            case 'artwork':
                return 'Artwork'
            case 'videos':
                return 'Videos'
            case 'mixing':
                return 'Mixing'
            case 'mastering':
                return 'Mastering'
            case 'lyrics':
                return 'Lyrics'
            case 'rap':
                return 'Rap'
        }
    }
    contributions.forEach(function(contribution){
        if(string && string.length > 0){
            string = `${string}, ${resolveType(contribution.type)}`
        }
        else {
            string = resolveType(contribution.type)
        }
    })
    if(string && string.length > 0){
        return `(${string})`
    }
    else {
        return null
    }
}
