<template>
    <div class="icons" :class="{dark: $store.state.settings.darkMode}">
        <div
            class="icon-wrapper"
            v-for="icon in icons"
            :key="icon.alt"
        >
            <a
                :href="icon.url"
                :alt="icon.alt"
                target="_blank"
            >
                <img :class="icon.type" :src="icon.image"/>
                <div class="hover-label">{{ icon.alt }}</div>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TitleIcons',

        props: ['links'],

        computed: {
            icons() {
                var icons = []
                this.links.forEach(function(link){
                    switch(link.type){
                        case 'website':
                            icons.push({
                                type: link.type,
                                alt: 'Website',
                                url: link.url,
                                image: require('@/assets/website-icon.png')
                            })
                            break

                        case 'facebook':
                            icons.push({
                                type: link.type,
                                alt: 'Facebook',
                                url: link.url,
                                image: require('@/assets/facebook-icon.png')
                            })
                            break

                        case 'instagram':
                            icons.push({
                                type: link.type,
                                alt: 'Instagram',
                                url: link.url,
                                image: require('@/assets/instagram-icon.png')
                            })
                            break

                        case 'twitter':
                            icons.push({
                                type: link.type,
                                alt: 'Twitter',
                                url: link.url,
                                image: require('@/assets/twitter-icon.png')
                            })
                            break

                        case 'bandcamp':
                            icons.push({
                                type: link.type,
                                alt: 'Bandcamp',
                                url: link.url,
                                image: require('@/assets/bandcamp-icon.png')
                            })
                            break

                        case 'soundcloud':
                            icons.push({
                                type: link.type,
                                alt: 'Soundcloud',
                                url: link.url,
                                image: require('@/assets/soundcloud-icon.png')
                            })
                            break

                        case 'spotify':
                            icons.push({
                                type: link.type,
                                alt: 'Spotify',
                                url: link.url,
                                image: require('@/assets/spotify-icon.png')
                            })
                            break

                        case 'apple_music':
                            icons.push({
                                type: link.type,
                                alt: 'Apple Music',
                                url: link.url,
                                image: require('@/assets/apple-music-icon.svg')
                            })
                            break

                        case 'deezer':
                            icons.push({
                                type: link.type,
                                alt: 'Deezer',
                                url: link.url,
                                image: require('@/assets/deezer-icon.svg')
                            })
                            break

                        case 'napster':
                            icons.push({
                                type: link.type,
                                alt: 'Napster',
                                url: link.url,
                                image: require('@/assets/napster-icon.svg')
                            })
                            break

                        case 'amazon_music':
                            icons.push({
                                type: link.type,
                                alt: 'Amazon Music',
                                url: link.url,
                                image: require('@/assets/amazon-icon.svg')
                            })
                            break

                        case 'youtube':
                            icons.push({
                                type: link.type,
                                alt: 'YouTube',
                                url: link.url,
                                image: require('@/assets/youtube-sw-icon.svg')
                            })
                            break

                        case 'tidal':
                            icons.push({
                                type: link.type,
                                alt: 'Tidal',
                                url: link.url,
                                image: require('@/assets/tidal-icon.svg')
                            })
                            break

                    }
                })
                return icons
            }
        }
    }
</script>

<style lang="scss" scoped>
    .icons {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-left: -10px;

        .icon-wrapper {
            margin: 0 10px;
            margin-bottom: 15px;
            height: 40px;
            display: flex;
            align-items: center;
            position: relative;

            @include breakpoint(tablet) {
                background: $dark1;
                padding: 0 16px;
            }
        }

        a {
            display: block;
            line-height: 0;
            text-decoration: none;

            @include breakpoint(tablet) {
                display: flex;
                align-items: center;
            }

            img {
                @include transition;
                height: 36px;

                @include breakpoint(tablet) {
                    filter: invert(1);
                    height: 20px;
                    margin-right: 5px;
                }

                &.soundcloud {
                    height: 48px;
                }

                &.deezer {
                    height: 28px;
                }

                &.tidal {
                    height: 28px;
                }
            }

            .hover-label {
                @include transition;
                opacity: 0;
                pointer-events: none;
                position: absolute;
                background: $dark2;
                color: $white;
                min-width: 50px;
                min-height: 18px;
                padding: 2px 5px 5px 5px;
                text-align: center;
                line-height: 20px;
                left: 50%;
                top: 50px;
                transform: translateX(-50%);
                white-space: nowrap;

                @include breakpoint(tablet) {
                    opacity: 1;
                    position: relative;
                    transform: unset;
                    left: unset;
                    top: unset;
                    background: none;
                }
            }

            &:hover {
                img {
                    transform: scale(1.1);
                }

                .hover-label {
                    opacity: 1;
                }
            }
        }

        &.dark {
            .icon-wrapper {
                @include breakpoint(tablet) {
                    background: rgba(255,255,255,0.1);
                }
            }

            a {
                img {
                    filter: invert(1);
                }

                .hover-label {
                    background: rgba(255,255,255,0.1);

                    @include breakpoint(tablet) {
                        background: none;
                    }
                }
            }
        }
    }
</style>
