<template>
    <div
        class="wrapper"
        :class="{'open': $store.state.cookieManagerOpen}"
    >
        <Overlay
            :open="$store.state.cookieManagerOpen"
            @click="closeCookieManager"
        />

        <div class="manager" :class="{dark: $store.state.settings.darkMode}">
            <div class="header">
                <div
                    v-if="$store.state.cookieManagerConfigured"
                    class="close-button"
                    @click="$store.commit('closeCookieManager')"
                >
                    <img src="@/assets/xmark.svg">
                </div>
                <div
                    class="title"
                    :class="{'no-close': !$store.state.cookieManagerConfigured}"
                >
                    Datenschutz-Einstellungen
                </div>
            </div>
            <div class="manager-content">
                <div class="info">
                    <p>
                        Die nachfolgenden externen Inhalte können auf Stuhrwerk.de eingebunden werden, wenn du es erlaubst. 
                    </p>
                    <p>
                        Weitere Informationen zu den einzelnen Inhalten findest du in unserer <router-link to="/privacy" @click.native="$store.commit('closeCookieManager')">Datenschutzerklärung</router-link>.
                    </p>
                </div>
                <div class="toggles">
                    <div
                        class="toggle-item"
                        v-for="toggle in settings"
                        :key="toggle.code"
                    >
                        <div class="label">
                            {{ toggle.title }}
                        </div>
                        <Toggle
                            v-model="toggle.value"
                            onLabel="Erlaubt"
                            offLabel="Verboten"
                        />
                    </div>
                </div>
            </div>
            <div class="buttons">
                <button
                    type="button"
                    class="button small red"
                    @click="setAll(false)"
                >
                    Alle verbieten
                </button>
                <button
                    type="button"
                    class="button small green"
                    @click="setAll(true)"
                >
                    Alle erlauben
                </button>
                <button
                    type="button"
                    class="button small save"
                    @click="saveSettings"
                >
                    Auswahl speichern
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Toggle from '@/components/Toggle'
    import Overlay from '@/components/Overlay'

    export default {
        name: 'CookieManager',

        components: {
            Toggle,
            Overlay
        },

        data() {
            return {
                settings: [{
                    title: 'YouTube Videos',
                    code: 'youtube',
                    value: this.$store.state.allowEmbeds.youtube
                },{
                    title: 'Bandcamp Player',
                    code: 'bandcamp',
                    value: this.$store.state.allowEmbeds.bandcamp
                },{
                    title: 'SoundCloud Player',
                    code: 'soundcloud',
                    value: this.$store.state.allowEmbeds.soundcloud
                },{
                    title: 'Spotify Player',
                    code: 'spotify',
                    value: this.$store.state.allowEmbeds.spotify
                }]
            }
        },

        computed: {
            allAllowed() {
                var allAllowed = true
                this.settings.forEach(function(item){
                    if(!item.value){
                        allAllowed = false
                    }
                })
                return allAllowed
            },

            allForbidden() {
                var allForbidden = true
                this.settings.forEach(function(item){
                    if(item.value){
                        allForbidden = false
                    }
                })
                return allForbidden
            }
        },

        methods: {
            setAll(val) {
                var self = this
                this.settings.forEach(function(item){
                    item.value = val
                })
                setTimeout(function(){
                    self.saveSettings()
                }, 400)
            },

            saveSettings() {
                var self = this
                this.settings.forEach(function(item){
                    if(item.value){
                        self.$store.commit('allowEmbed', item.code)
                    }
                    else {
                        self.$store.commit('forbidEmbed', item.code)
                    }
                })
                this.$store.commit('setCookieManagerConfigured')
                this.$store.commit('closeCookieManager')
            },

            refreshSettings() {
                var self = this
                this.settings.forEach(function(item){
                    item.value = self.$store.state.allowEmbeds[item.code]
                })
            },

            closeCookieManager() {
                if(this.$store.state.cookieManagerConfigured){
                    this.$store.commit('closeCookieManager')
                }
            }
        },

        mounted() {
            if(!this.$store.state.cookieManagerConfigured){
                this.$store.commit('openCookieManager')
            }
            this.$setBodyLock()
        },

        watch: {
            '$store.state.cookieManagerOpen': {
                handler() {
                    this.refreshSettings()
                    this.$setBodyLock()
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        z-index: 990;

        &.open {
            .manager {
                transform: translateY(0);
            }
        }

        .manager {
            transform: translateY(-150vh);
            @include transition;
            width: 450px;
            background: $white;
            padding: 20px;
            display: flex;
            flex-direction: column;
            z-index: 999;
            pointer-events: all;

            @include breakpoint(mobile) {
                width: calc(100% - 40px);
                max-width: 450px;
                max-height: calc(100% - 40px);
            }

            .header {
                width: 100%;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;

                .title {
                    width: 100%;
                    flex-grow: 1;
                    padding-right: 20px;
                    text-align: center;
                    font-family: $accentFont;
                    @include fontSize(xl);
                    font-weight: 700;

                    &.no-close {
                        padding-left: 20px;
                    }
                }

                .close-button {
                    cursor: pointer;
                    @include transition;

                    &:hover {
                        transform: scale(1.1);
                    }

                    img {
                        height: 32px;
                    }
                }
            }

            .manager-content {
                flex-shrink: 1;
                overflow-y: scroll;

                .info {
                    border-bottom: 1px solid $light2;
                    margin-bottom: 10px;
                }

                .toggles {

                    .toggle-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid $light2;
                        margin-bottom: 10px;
                        padding-bottom: 10px;

                        .label {
                            @include fontSize(xs);
                            font-weight: 500;
                        }
                    }
                }
            }

            .buttons {
                margin-top: 10px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 10px;

                .save {
                    grid-column-start: 1;
                    grid-column-end: span 2;
                    margin-top: 10px;
                }

                .button {
                    width: 100%;
                }
            }

            &.dark {
                background: $dark2;

                .header {
                    .close-button {
                        img {
                            filter: invert(1);
                        }
                    }
                }
            }
        }
    }
</style>
