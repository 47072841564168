<template>
    <div class="notification" :class="notification.type">
        <div class="header">
            <div class="title">
                {{ errorType }}
            </div>
            <div class="close">
                <img
                    src="@/assets/xmark.svg"
                    @click="$store.commit('killNotification', notification)"
                />
            </div>
        </div>
        <div class="message">
            {{ notification.message }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Notification',

        props: ['notification'],

        computed: {
            errorType() {
                switch(this.notification.type){
                    case 'error':
                        return 'Fehler'
                    case 'success':
                        return 'Erfolgreich'
                    default:
                        return 'Hinweis'
                }
            }
        },

        mounted() {
            if(this.notification.type == 'success'){
                var self = this
                setTimeout(function(){
                    self.$store.commit('killNotification', self.notification)
                }, 2500)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .notification {
        color: $white;
        box-shadow: $boxShadowS;
        background: $dark3;
        width: 300px;
        @include transition;

        @include breakpoint(mobile) {
            width: 100%;
        }

        &.error {
            background: $red;
        }

        &.success {
            background: $green;
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;
            flex-grow: 1;
            background: rgba(255,255,255,0.2);
            font-weight: 700;
            @include fontSize(s);
            font-family: $accentFont;
        }

        .close {
            filter: invert(1);
            cursor: pointer;

            img {
                height: 20px;
                @include transition;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }

        .message {
            padding: 10px 20px;
            font-weight: 500;
            @include fontSize(xxs);
        }
    }
</style>
