<template>
    <div class="back" v-if="show" :class="{dark: $store.state.settings.darkMode}">
        <img src="@/assets/angle.svg">
        <router-link :to="to">{{ to.name }}</router-link>
    </div>
</template>

<script>
    export default {
        name: 'BackButton',

        computed: {
            to() {
                var to = {name: null}
                switch(this.$route.name){
                    case 'Artist':
                        to.name = 'Artists'
                        break
                    case 'Release':
                        to.name = 'Releases'
                        break
                    case 'Product':
                    case 'Checkout':
                    case 'OrderSuccess':
                        to.name = 'Shop'
                        break
                    case 'Artists':
                    case 'Releases':
                    case 'Shop':
                    case 'Imprint':
                    case 'About':
                    case 'Contact':
                    case 'PrivacyPolicy':
                    case '404':
                    case 'AGB':
                    case 'Cancellation':
                        to.name = 'Home'
                        break
                }
                return to
            },

            show() {
                return this.$route.name != 'Home'
            }
        }
    }
</script>

<style lang="scss" scoped>
    .back {
        display: none;
        z-index: 9;
        align-items: center;
        margin-left: 20px;
        @include fontSize(xxs);

        @include breakpoint(tablet) {
            display: flex;
        }

        @include mobileClick;

        img {
            height: 20px;
            margin-right: 3px;
        }

        a {
            text-decoration: none;
            font-weight: 500;
            text-transform: uppercase;
            transform: translateY(-0.5px);
            color: $dark1;
        }

        &.dark {
            img {
                filter: invert(1);
            }

            a {
                color: $light3;
            }
        }
    }
</style>
