<template>
    <div id="app" :class="appClasses">
        <AutoColorBg/>
        <NavBar/>
        <Notifications/>
        <div id="main">

            <!-- With Animations -->
            <Transition
                v-if="$store.state.settings.animations"
                :name="$store.state.transition"
                :duration="250"
                @after-leave="$root.$emit('triggerScroll')"
            >
                <RouterView :key="$route.fullPath" class="router-view">
                    <template #footer>
                        <div class="filler"></div>
                        <Footer/>
                    </template>
                </RouterView>
            </Transition>

            <!-- Without Animations -->
            <RouterView v-else :key="$route.fullPath" class="router-view">
                <template #footer>
                    <div class="filler"></div>
                    <Footer/>
                </template>
            </RouterView>

        </div>
        <Cart v-if="$config.enableShop"/>
        <Search/>
        <CookieManager/>
        <Settings/>
    </div>
</template>

<script>
    import NavBar from '@/components/NavBar'
    import Cart from '@/components/Cart'
    import Notifications from '@/components/Notifications'
    import Footer from '@/components/Footer'
    import CookieManager from '@/components/CookieManager'
    import Settings from '@/components/Settings'
    import Search from '@/components/Search'
    import AutoColorBg from '@/components/AutoColorBg'
    import { settingsQuery } from '@/clients/strapi'

    export default {
        name: 'App',

        components: {
            NavBar,
            Cart,
            Notifications,
            Footer,
            CookieManager,
            Settings,
            Search,
            AutoColorBg
        },

        apollo: {
            settings: {
                query: settingsQuery,
                update: data => data.setting.data.attributes
            }
        },

        computed: {
            metaDescription() {
                if(this.settings){
                    return this.settings.page_description
                }
                else {
                    return null
                }
            },

            appClasses() {
                return {
                    'no-animations': !this.$store.state.settings.animations,
                    'dark': this.$store.state.settings.darkMode
                }
            }
        },

        methods: {
            handleDarkMode() {
                if(
                    window.matchMedia &&
                    window.matchMedia('(prefers-color-scheme: dark)').matches
                ){
                    // dark mode
                    if(this.$store.state.settings.autoDarkMode){
                        this.$store.commit('setSettingTrue', 'darkMode')
                    }
                }
                else {
                    // light mode
                    if(this.$store.state.settings.autoDarkMode){
                        this.$store.commit('setSettingFalse', 'darkMode')
                    }
                }
            },

            initDarkModeEventListener() {
                var self = this
                window.matchMedia('(prefers-color-scheme: dark)')
                    .addEventListener('change', function(event){
                        // dark mode
                        if(
                            event.matches &&
                            self.$store.state.settings.autoDarkMode
                        ){
                            self.$store.commit('setSettingTrue', 'darkMode')
                        }
                        // light mode
                        else if(
                            !event.matches &&
                            self.$store.state.settings.autoDarkMode
                        ){
                            self.$store.commit('setSettingFalse', 'darkMode')
                        }
                    })
            }
        },

        mounted() {
            var self = this

            if(this.$config.enableShop && !this.$store.state.regionId){
                this.$store.dispatch('fetchRegion')
            }

            this.$store.commit('closeCart')
            this.$store.commit('closeSearch')
            this.$store.commit('finishedLoading')

            window.addEventListener('keydown', function(e){
                if(e.key == '/' && !self.$store.state.searchOpen){
                    self.$store.commit('openSearch')
                }
            })

            this.handleDarkMode()
            this.initDarkModeEventListener()
        },

        watch: {
            '$store.state.settings.autoDarkMode': {
                handler() {
                    if(this.$store.state.settings.autoDarkMode){
                        this.handleDarkMode()
                    }
                }
            }
        },

        metaInfo() {
            var description = this.metaDescription
            return {
                titleTemplate: 'Stuhrwerk %s',
                meta: [{
                    name: 'description',
                    content: description
                }]
            }
        }
    }
</script>

<style lang="scss">
    @import "~@splidejs/splide/dist/css/splide.min.css";

    body {
        margin: 0;
    }

    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        color: $black;
    }

    #main {
        flex-grow: 1;
        height: 100vh;
        overflow-y: scroll;
    }

    .filler {
        flex-grow: 1;
    }

    .router-view {
        position: absolute;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-top: 72px;
        transition-duration: .25s;
        transition-property: transform, opacity;
        /* will-change: transform, opacity; */
        left: 0;
        right: 0;
        min-height: calc(100vh - 100px);
        transform: translateX(0vw); 

        @include breakpoint(tablet) {
            margin-top: 48px;
        }

        @include breakpoint(mobile) {
            min-height: calc(100vh - 50px);
        }
    }

    /* swap transition */
    .swap-enter {
        opacity: 0;
    }
    .swap-leave-to {
        opacity: 0;
    }

    /* swipe fw transition */
    .swipe-fw-enter {
        transform: translateX(100vw);
    }
    .swipe-fw-leave-to {
        transform: translate(-100vw);
    }

    /* swipe rev transition */
    .swipe-rev-enter {
        transform: translateX(-100vw);
    }
    .swipe-rev-leave-to {
        transform: translate(100vw);
    }

    .swap-enter,
    .swap-leave-to,
    .swipe-fw-enter,
    .swipe-fw-leave-to,
    .swipe-rev-enter,
    .swipe-rev-leave-to,
    .swap-leave-active,
    .swap-enter-active,
    .swipe-fw-enter-active,
    .swipe-fw-leave-active,
    .swipe-rev-enter-active,
    .spipe-rev-leave-active {

        .footer {
            display: none;
        }
    }
</style>
