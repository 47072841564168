import { loadScript } from "@paypal/paypal-js";
import { store } from '@/store'
import { config } from '@/config'

export const paypalPlugin = {
    install(Vue) {
        if(config.enableShop){
            loadScript({
                'client-id': config.paypalClientId,
                'currency': 'EUR',
                'intent': 'authorize',
            })
            .then(function(paypal){
                paypal.Buttons.driver("vue", Vue)
            })
            .catch(function(err){
                console.log(err)
                store.commit('pushError', 'PayPal konnte nicht geladen werden')
            })
        }
    }
}
