<template>
    <div class="spotify">
        <LoadingPlaceholder :borderRadius="12"/>
        <iframe
            v-if="$store.state.allowEmbeds.spotify"
            class="spotify-iframe"
            frameBorder="0"
            :src="url"
        ></iframe>
        <ForbiddenPlayer v-else embed="spotify">
            <p>
                Du hast das Einbinden von Spotify Playern auf Stuhrwerk.de verboten. Klicke unten, um diese Einstellung zu ändern.
            </p>
            <p>
                Weitere Informationen zu eingebundenen Inhalten findest du in unserer <router-link to="/privacy" @click.native="$store.commit('closeCookieManager')">Datenschutzerklärung</router-link>.
            </p>
        </ForbiddenPlayer>
    </div>
</template>

<script>
    import ForbiddenPlayer from '@/components/players/ForbiddenPlayer'
    import LoadingPlaceholder from '@/components/LoadingPlaceholder'

    export default {
        name: 'SpotifyPlayer',

        components: {
            ForbiddenPlayer,
            LoadingPlaceholder
        },

        props: ['spotify_album_id'],

        computed: {
            url() {
                return `https://open.spotify.com/embed/album/${this.spotify_album_id}?utm_source=generator&theme=0`
            }
        }
    }
</script>

<style lang="scss" scoped>
    .spotify {
        box-shadow: 0 0 60px rgba(0,0,0,0.2);
        width: 100%;
        height: 100%;
        position: relative;

        @include breakpoint(mobile) {
            height: 400px;
        }

        .spotify-iframe {
            border: 0;
            width: 100%;
            height: 100%;
            position: relative;
        }
    }
</style>
