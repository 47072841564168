<template>
    <div class="cart-button" :class="{'open': $store.state.cartOpen, 'dark': $store.state.settings.darkMode}" @click="$store.commit('openCart')">
        <img class="icon" src="@/assets/cart.svg"/>
        <div v-if="$store.getters.totalCartQty > 0" class="badge">
            {{ $store.getters.totalCartQty }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CartButton'
    }
</script>

<style lang="scss" scoped>
    .cart-button {
        position: relative;
        height: 46px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        cursor: pointer;

        &:hover,
        .open {
            background: rgba(0, 0, 0, 0.1);

            .icon {
                opacity: 1;
            }
        }

        .icon {
            height: 24px;
            opacity: 0.8;
            @include transition;

            @include breakpoint('mobile') {
                height: 22px;
            }
        }

        .badge {
            position: absolute;
            top: 4px;
            right: 6px;
            height: 16px;
            padding: 0 7px;
            color: white;
            background: red;
            @include fontSize(xxxs);
            line-height: 16px;
            text-align: center;
            border-radius: 8px;
        }

        &.dark {
            .icon {
                opacity: 0.9;
                filter: invert(1);
            }

            &:hover,
            .open {
                background: rgba(255,255,255,0.1);
            }
        }
    }
</style>
