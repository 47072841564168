<template>
    <div class="catalog-numbers" :class="{dark: $store.state.settings.darkMode}">
        <div
            class="number"
            v-for="catalogNumber in catalogNumbers"
            :key="catalogNumber.number"
        >
            <img :src="getIcon(catalogNumber.type)">
            {{ catalogNumber.number }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CatalogNumbers',

        props: ['catalogNumbers'],

        methods: {
            getIcon(type) {
                switch(type){
                    case 'cd':
                        return require('@/assets/disc.svg')

                    case 'digital':
                        return require('@/assets/cloud.svg')

                    case 'vinyl':
                        return require('@/assets/vinyl.svg')

                    case 'tape':
                        return require('@/assets/tape.svg')

                    default:
                        return require('@/assets/disc.svg')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .catalog-numbers {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;

        .number {
            display: flex;
            align-items: center;
            @include fontSize(sm);
            font-weight: 500;
            color: $white;
            background: $dark1;
            padding: 10px 20px;
            padding-left: 15px;
            border-radius: 50px;
            line-height: 0;

            img {
                height: 20px;
                filter: invert(1);
                margin-right: 10px;
            }
        }

        &.dark {
            .number {
                background: rgba(255,255,255,0.1);
            }
        }
    }
</style>
