<template>
    <div class="forbidden-player" :class="classes">
        <div class="forbidden-player-content">
            <img class="icon" src="@/assets/forbidden.svg"/>
            <div class="message">
                <slot/>
            </div>
            <Toggle v-model="allowEmbed" onLabel="Erlaubt" offLabel="Verboten"/>
        </div>
    </div>
</template>

<script>
    import Toggle from '@/components/Toggle'

    export default {
        name: 'ForbiddenPlayer',

        components: {
            Toggle
        },

        props: [
            'embed',
            'small'
        ],

        data() {
            return {
                allowEmbed: this.$store.state.allowEmbeds[this.embed]
            }
        },

        computed: {
            classes() {
                return {
                    small: this.small,
                    dark: this.$store.state.settings.darkMode
                }
            }
        },

        watch: {
            allowEmbed() {
                if(this.allowEmbed){
                    var self = this
                    setTimeout(function(){
                        self.$store.commit('allowEmbed', self.embed)
                    }, 400)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .forbidden-player {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        background: $white;
        position: relative;

        .forbidden-player-content {
            flex-grow: 1;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .icon {
                opacity: 0.3;
                width: 50%;
                max-width: 150px;
                margin-bottom: 20px;
            }

            .message {
                @include fontSize(xs);
                margin-bottom: 10px;
                text-align: center;
            }
        }

        &.small {
            .forbidden-player-content {
                .icon {
                    width: 50px;
                    margin-bottom: 20px;

                    @include breakpoint(mobile) {
                        display: none;
                    }
                }

                .message {
                    margin-bottom: 0;

                    @include breakpoint(mobile) {
                        @include fontSize(xxxs);
                    }
                }
            }
        }

        &.dark {
            background: $black;

            .forbidden-player-content {
                .icon {
                    filter: invert(1);
                }
            }
        }
    }
</style>
