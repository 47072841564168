<template>
    <div class="video" :class="{dark: $store.state.settings.darkMode}">
        <div class="wrapper">
            <div class="rendering-area" >
                <LoadingPlaceholder/>
                <div
                    class="youtube"
                    v-if="$store.state.allowEmbeds.youtube"
                    @click="play = true"
                >
                    <img v-if="!play" v-lazy="thumb"/>
                    <iframe
                        v-else
                        :src="src"
                        class="youtube-iframe"
                        frameborder="0"
                        allowfullscreen="true"
                    ></iframe>
                </div>
                <ForbiddenPlayer
                    v-else
                    class="forbidden"
                    embed="youtube"
                    :small="true"
                >
                    Du hast das Einbinden von YouTube Videos auf Stuhrwerk.de verboten. Klicke unten, um diese Einstellung zu ändern.
                    <br>
                    <br>
                    Weitere Informationen zu eingebundenen Inhalten findest du in unserer <router-link to="/privacy" @click.native="$store.commit('closeCookieManager')">Datenschutzerklärung</router-link>.
                    <br>
                    <br>
                </ForbiddenPlayer>
            </div>
            <div class="title" v-if="title">{{ title }}</div>
        </div>
    </div>
</template>

<script>
    import ForbiddenPlayer from '@/components/players/ForbiddenPlayer'
    import LoadingPlaceholder from '@/components/LoadingPlaceholder'

    export default {
        name: 'YoutubeVideo',

        components: {
            ForbiddenPlayer,
            LoadingPlaceholder
        },

        props: [
            'videoId',
            'title'
        ],

        data() {
            return {
                play: false
            }
        },

        computed: {
            src() {
                if(this.videoId){
                    return `https://www.youtube-nocookie.com/embed/${this.videoId}`
                }
                else {
                    return null
                }
            },
            thumb() {
                if(this.videoId){
                    return `https://i1.ytimg.com/vi/${this.videoId}/0.jpg`
                }
                else {
                    return null
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .video {
        display: flex;
        flex-direction: column;

        .wrapper {
            @include transition;

            .rendering-area {
                background: transparent;
                height: 0;
                padding-bottom: calc(56.25%);
                position: relative;
                width: 100%;
                box-shadow: $boxShadowM;
                @include transition;
            }

            .youtube,
            .forbidden {
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }

            .youtube-iframe,
            img {
                height: 100%;
                width: 100%;
            }

            img {
                object-fit: cover;
                cursor: pointer;
            }

            .title {
                padding: 10px 15px 15px;
                font-weight: 700;
                @include fontSize(xs);
                @include transition;
                text-align: center;
            }

            &:hover {
                transform: scale(1.04);
                box-shadow: $boxShadowHover;

                .rendering-area {
                    box-shadow: $boxShadowMInvisible;
                }

                .title {
                    background: white;
                }
            }
        }

        &.dark {
            .wrapper {
                &:hover {
                    .title {
                        background: rgba(255,255,255,0.075);
                    }
                }
            }
        }
    }
</style>
