<template>
    <div>
        <router-link to="/imprint" exact>
            <div class="menu-item">
                Impressum
            </div>
        </router-link>

        <router-link to="/privacy" exact>
            <div class="menu-item">
                Datenschutz
            </div>
        </router-link>

        <router-link to="/agb" exact v-if="$config.enableShop">
            <div class="menu-item">
                AGB
            </div>
        </router-link>

        <router-link to="/cancellation" exact v-if="$config.enableShop">
            <div class="menu-item">
                Widerrufsrecht
            </div>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'FooterRouterLinks'
    }
</script>
