<template>
    <div class="toggle" :class="classes" @click="toggle">
        <span class="label">
            {{ label }}
        </span>
    </div>
</template>

<script>
    export default {
        name: 'Toggle',

        props: [
            'modelValue',
            'label'
        ],

        model: {
            prop: 'modelValue',
            event: 'update'
        },

        emits: ['update'],

        computed: {
            classes() {
                return {
                    checked: this.modelValue
                }
            }
        },

        methods: {
            toggle() {
                this.$emit('update', !this.modelValue)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .toggle {
        position: relative;
        display: inline-block;
        background-color: $dark3;
        height: 34px;
        border-radius: 34px;
        cursor: pointer;
        padding: 0 15px;
        @include transition;

        .label {
            font-family: $accentFont;
            @include fontSize(s);
            color: white;
            font-weight: 500;
            line-height: 36px;
            width: 100%;
            display: block;
            text-align: center;
        }

        &.checked {
            background-color: $yellow;
        }
    }
</style>
