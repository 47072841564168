<template>
    <Grid size="6">
        <GridItem
            v-for="release in results"
            :key="release.id"
            type="cover"
            :url="`/releases/${release.url_key}`"
            :image="getImage(release)"
            :title_one="release.title"
            :title_two="release.artist"
            :small="true"
        />
    </Grid>
</template>

<script>
    import Grid from '@/components/Grid'
    import GridItem from '@/components/GridItem'

    export default {
        name: 'ReleaseResults',

        components: {
            Grid,
            GridItem
        },

        props: ['results'],

        methods: {
            getImage(release) {
                if(release.cover){
                    if(release.cover.formats && release.cover.formats.small){
                        return this.$getImageUrl(release.cover.formats.small.url)
                    }
                    else {
                        return this.$getImageUrl(release.cover.url)
                    }
                }
                else {
                    return require('@/assets/blank-release.png')
                }
            }
        }
    }
</script>
