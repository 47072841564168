<template>
    <div class="reactable" :class="{'loading': $store.state.loading}">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: 'ApiReactable'
    }
</script>

<style lang="scss" scoped>
    .reactable.loading {
        cursor: default;
        animation: loadingAnimation 1.5s ease 0s infinite normal forwards;
    }

    @keyframes loadingAnimation {
        0%, 50%, 100% {
            opacity: 0.6;
        }
        25%, 75% {
            opacity: 0.3;
        }
    }
</style>
