import axios from 'axios'
import { config } from '@/config'

const youtubeClient = axios.create({
    baseURL: 'https://youtube.googleapis.com/youtube/v3/',
    params: {
        key: config.youtubeApiKey
    }
})

export const youtube = {
    getLatestVideos(limit = 10) {
        return youtubeClient.get('playlistItems', {
            params: {
                part: 'contentDetails,snippet',
                playlistId: config.youtubePlaylistId,
                maxResults: limit
            }
        })
    },

    procecssPlaylistItems(res) {
        var videos = []
        if(res.data && res.data.items){
            res.data.items.forEach(function(item){
                if(item.contentDetails){
                    var video = {
                        videoId: item.contentDetails.videoId
                    }
                    if(item.snippet){
                        video.title = item.snippet.title
                    }
                    videos.push(video)
                }
            })
        }
        return videos
    },

    getVideo(videoId) {
        return youtubeClient.get('videos', {
            params: {
                part: 'snippet',
                id: videoId
            }
        })
    }
}

export const youtubePlugin = {
    install(Vue) {
        Vue.prototype.$youtube = youtube
    }
}
