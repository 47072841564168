import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import router from '@/router'
import { medusa } from '@/clients/medusa'
import { hashString } from '@/prototypes'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        notifications: [],
        releaseRoutes: [],
        artistRoutes: [],
        cart: null,
        cartId: null,
        cartOpen: false,
        region: null,
        regionId: null,
        shippingOptions: [],
        loading: false,
        transition: 'swap',
        lastOrder: null,
        errorCounter: 0,
        allowEmbeds: {
            youtube: false,
            bandcamp: false,
            soundcloud: false,
            spotify: false
        },
        cookieManagerConfigured: false,
        cookieManagerOpen: false,
        burgerMenuOpen: false,
        searchOpen: false,
        searchQuery: null,
        pageBackground: null,
        settings: {
            animations: true,
            pageBackground: true,
            smallItems: false,
            darkMode: false,
            autoDarkMode: true
        },
        settingsOpen: false
    },

    getters: {
        totalCartQty(state) {
            if(state.cart && state.cart.items.length > 0){
                var qty = 0
                state.cart.items.forEach(function(item){
                    qty += item.quantity
                })
                return qty
            }
            else {
                return 0
            }
        }
    },

    mutations: {
        pushError(state, message) {
            state.notifications.push({
                id: hashString(),
                type: 'error',
                message: message
            })
        },

        pushSuccess(state, message) {
            state.notifications.push({
                id: hashString(),
                type: 'success',
                message: message
            })
        },

        pushMessage(state, message) {
            state.notifications.push({
                id: hashString(),
                type: 'notice',
                message: message
            })
        },

        killNotification(state, notification) {
            state.notifications.forEach(function(n, i){
                if(n.id == notification.id){
                    state.notifications.splice(i, 1)
                }
            })
        },

        setReleaseRoutes(state, routes) {
            state.releaseRoutes = routes
        },

        setArtistRoutes(state, routes) {
            state.artistRoutes = routes
        },

        setRegion(state, region) {
            state.region = region
            state.regionId = region.id
        },

        setCart(state, cart) {
            state.cart = cart
            state.cartId = cart.id
        },

        isLoading(state) {
            state.loading = true
        },

        finishedLoading(state) {
            state.loading = false
        },

        deleteCart(state) {
            state.cart = null
            state.cartId = null
        },

        openCart(state) {
            if(!state.cookieManagerOpen){
                state.cartOpen = true
            }
        },

        closeCart(state) {
            state.cartOpen = false
        },

        setTransition(state, transition) {
            state.transition = transition
        },

        setOrder(state, order) {
            state.lastOrder = order
        },

        incrementErrorCounter(state) {
            state.errorCounter += 1
        },

        resetErrorCounter(state) {
            state.errorCounter = 0
        },

        allowEmbed(state, type) {
            state.allowEmbeds[type] = true
        },

        forbidEmbed(state, type) {
            state.allowEmbeds[type] = false
        },

        setCookieManagerConfigured(state) {
            state.cookieManagerConfigured = true
        },

        openCookieManager(state) {
            state.cookieManagerOpen = true
        },

        closeCookieManager(state) {
            state.cookieManagerOpen = false
        },

        openBurgerMenu(state) {
            if(!state.cookieManagerOpen){
                state.burgerMenuOpen = true
            }
        },

        closeBurgerMenu(state) {
            state.burgerMenuOpen = false
        },

        openSearch(state) {
            if(!state.cookieManagerOpen){
                state.searchOpen = true
            }
        },

        closeSearch(state) {
            state.searchOpen = false
        },

        setSearchQuery(state, query) {
            state.query = query
        },

        clearSearchQuery(state) {
            state.query = null
        },

        setPageColors(state, colors) {
            state.pageColors = colors
        },

        resetPageColors(state) {
            state.pageColors = [{
                hex: '#fff'
            }]
        },

        openSettings(state) {
            state.settingsOpen = true
        },

        closeSettings(state) {
            state.settingsOpen = false
        },

        setSettingTrue(state, setting) {
            state.settings[setting] = true
        },

        setSettingFalse(state, setting) {
            state.settings[setting] = false
        }
    },

    actions: {
        fetchRegion(context) {
            medusa.regions.list()
            .then(function(res){
                res.regions.forEach(function(region){
                    if(region.name == 'DE'){
                        context.commit('setRegion', region)
                    }
                })
            })
        },

        updateCart(context) {
            context.commit('isLoading')
            medusa.carts.retrieve(context.state.cartId)
            .then(function(res){
                context.commit('setCart', res.cart)
                context.commit('finishedLoading')
            })
            .catch(function(err){
                console.log(err)
                context.commit('deleteCart')
                context.commit('finishedLoading')
            })
        },

        recreateCart(context) {
            context.commit('isLoading')
            context.commit('resetErrorCounter')
            var backup = []
            if(context.state.cart){
                context.state.cart.items.forEach(function(item){
                    backup.push({
                        title: item.variant.title,
                        variantId: item.variant_id,
                        qty: item.quantity,
                        silent: true
                    })
                })
            }
            context.commit('deleteCart')
            medusa.carts.create({
                region_id: context.state.regionId
            })
            .then(function(res){
                context.commit('setCart', res.cart)
                console.log(backup)
                backup.forEach(function(item){
                    context.dispatch('addToCart', item)
                })
            })
            .catch(function(err){
                console.log(err)
                context.commit('finishedLoading')
            })
        },

        addToCart(context, product) {
            context.commit('isLoading')
            if(context.state.cart){
                medusa.carts.lineItems.create(context.state.cartId, {
                    variant_id: product.variantId,
                    quantity: product.qty
                })
                .then(function(res){
                    context.commit('setCart', res.cart)
                    context.commit('finishedLoading')
                    if(!product.silent){
                        context.commit(
                            'pushSuccess',
                            `"${product.title}" wurde zum Warenkorb hinzugefügt`
                        )
                        context.commit('openCart')
                    }
                })
                .catch(function(err){
                    console.log(err)
                    context.commit('finishedLoading')
                    context.commit('incrementErrorCounter')
                    if(!product.silent){
                        context.commit(
                            'pushError',
                            `"${product.title}" konnte nicht zum Warenkorb hinzugefügt werden`
                        )
                    }
                })
            }
            else {
                // create a cart
                medusa.carts.create({
                    region_id: context.state.regionId
                })
                .then(function(res){
                    context.commit('setCart', res.cart)
                    medusa.carts.lineItems.create(res.cart.id, {
                        variant_id: product.variantId,
                        quantity: product.qty
                    })
                    .then(function(res){
                        context.commit('setCart', res.cart)
                        context.commit('finishedLoading')
                        if(!product.silent){
                            context.commit(
                                'pushSuccess',
                                `"${product.title}" wurde zum Warenkorb hinzugefügt`
                            )
                        }
                        context.commit('openCart')
                    })
                    .catch(function(err){
                        console.log(err)
                        context.commit('finishedLoading')
                        context.commit('incrementErrorCounter')
                        if(!product.silent){
                            context.commit(
                                'pushError',
                                `"${product.title}" konnte nicht zum Warenkorb hinzugefügt werden`
                            )
                        }
                    })
                })
                .catch(function(err){
                    console.log(err)
                    context.commit('finishedLoading')
                    context.commit('incrementErrorCounter')
                    if(!product.silent){
                        context.commit(
                            'pushError',
                            'Es konnte kein Warenkorb angelegt werden.'
                        )
                    }
                })
            }
        },

        updateCartItem(context, item) {
            context.commit('isLoading')
            medusa.carts.lineItems.update(
                context.state.cartId,
                item.id,
                {
                    quantity: item.qty
                }
            )
            .then(function(res){
                context.commit('setCart', res.cart)
                context.commit('finishedLoading')
            })
            .catch(function(err){
                console.log(err)
                context.commit('finishedLoading')
                context.commit('incrementErrorCounter')
                context.commit(
                    'pushError',
                    'Artikel konnte nicht bearbeitet werden'
                )
            })
        },

        deleteCartItem(context, item) {
            context.commit('isLoading')
            medusa.carts.lineItems.delete(
                context.state.cartId,
                item.id
            )
            .then(function(res){
                context.commit('setCart', res.cart)
                context.commit('finishedLoading')
                context.commit(
                    'pushSuccess',
                    `"${item.title}" wurde aus dem Warenkorb entfernt`
                )
            })
            .catch(function(err){
                console.log(err)
                context.commit('finishedLoading')
                context.commit('incrementErrorCounter')
                context.commit(
                    'pushError',
                    `"${item.title}" konnte aus dem Warenkorb entfernt werden`
                )
            })
        },

        updateAddress(context, form) {
            context.commit('isLoading')
            medusa.carts.update(context.state.cartId, {
                email: form.email,
                shipping_address: {
                    company: null,
                    first_name: form.firstname,
                    last_name: form.lastname,
                    address_1: form.address1,
                    address_2: form.address2,
                    city: form.city,
                    country_code: form.countryCode,
                    province: null,
                    postal_code: form.postalCode,
                    phone: form.phone
                },
            }).then(function(res){
                context.commit('setCart', res.cart)
                context.dispatch('updateShippingOptions')
                context.commit('finishedLoading')
            })
            .catch(function(err){
                console.log(err)
                context.commit('finishedLoading')
                context.commit(
                    'pushError',
                    'Adresse konnte nicht gespeichert werden'
                )
            })
        },

        updateShippingOptions(context) {
            context.commit('isLoading')
            medusa.shippingOptions.listCartOptions(context.state.cartId)
            .then(function(res){
                context.state.shippingOptions = res.shipping_options
                if(res.shipping_options.length > 0 && context.state.cart.shipping_methods.length == 0){
                    context.dispatch('selectShippingOption', res.shipping_options[0])
                } else {
                    context.commit('finishedLoading')
                }
            })
            .catch(function(err){
                console.log(err)
                context.commit('finishedLoading')
                context.commit(
                    'pushError',
                    'Versandarten konnten nicht ermittelt werden'
                )
            })
        },

        selectShippingOption(context, option) {
            context.commit('isLoading')
            medusa.carts.addShippingMethod(context.state.cartId, {
                option_id: option.id
            })
            .then(function(res){
                context.commit('setCart', res.cart)
                context.commit('finishedLoading')
            })
            .catch(function(err){
                console.log(err)
                context.commit('finishedLoading')
                context.commit(
                    'pushError',
                    'Versandart konnten nicht gespeichert werden'
                )
            })
        },

        initPaymentSessions(context) {
            context.commit('isLoading')
            if(context.state.cart.payment_sessions.length == 0){
                medusa.carts.createPaymentSessions(context.state.cartId)
                .then(function(res){
                    context.commit('setCart', res.cart)
                    context.commit('finishedLoading')
                })
                .catch(function(err){
                    console.log(err)
                    context.commit('finishedLoading')
                    context.commit(
                        'pushError',
                        'Zahlungssession konnte nicht initialisiert werden'
                    )
                })
            }
            else {
                context.commit('finishedLoading')
            }
        },

        setPaymentSession(context, session) {
            context.commit('isLoading')
            medusa.carts.setPaymentSession(
                context.state.cartId,
                {
                    provider_id: session.provider_id
                }
            )
            .then(function(res){
                context.commit('setCart', res.cart)
                context.commit('finishedLoading')
            })
            .catch(function(err){
                console.log(err)
                context.commit('finishedLoading')
                context.commit(
                    'pushError',
                    'Zahlungsart konnte nicht ausgewählt werden'
                )
            })
        },

        updatePaymentSession(context, authorization) {
            console.log('authorization: ', authorization)
            context.commit('isLoading')
            medusa.carts.updatePaymentSession(
                context.state.cartId,
                context.state.cart.payment_session.provider_id,
                {
                    data: {
                        ...authorization
                    }
                }
            )
            .then(function(res){
                console.log('updatePaymentSession. Next is complete Cart', res.cart)
                context.commit('setCart', res.cart)
                context.dispatch('completeCart')
            })
            .catch(function(err){
                console.log(err)
                context.commit('finishedLoading')
                context.commit(
                    'pushError',
                    'Zahlungssession konnte nicht aktualisiert werden'
                )
            })
        },

        completeCart(context) {
            context.commit('isLoading')
            medusa.carts.complete(context.state.cartId)
            .then(function(res){
                console.log(res)
                if(res.type == 'order'){
                    console.log(res)
                    context.commit('setOrder', res.data.display_id)
                    context.commit('deleteCart')
                    context.commit('resetCheckout')
                    router.push({name: 'OrderSuccess'})
                }
                else {
                    console.error('err complete', res)
                    context.commit(
                        'pushError',
                        'Bestellung konnte nicht abgeschlossen werden'
                    )
                }
                context.commit('finishedLoading')
            })
        }
    },

    plugins: [vuexLocal.plugin]
})

export const storePlugin = {
    install(Vue) {
        Vue.prototype.$store = store
    }
}
