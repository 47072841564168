<template>
    <div class="clickable" :class="{'loading': $store.state.loading}" @click="emitClick">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: 'ApiClickable',

        emits: ['click'],

        methods: {
            emitClick() {
                if(!this.$store.state.loading){
                    this.$emit('click')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .loading {
        cursor: progress !important;
        animation: loadingAnimation 1.5s ease 0s infinite normal forwards;
    }

    @keyframes loadingAnimation {
        0%, 50%, 100% {
            opacity: 0.6;
        }
        25%, 75% {
            opacity: 0.3;
        }
    }
</style>
