<template>
    <div class="cart-wrapper" :class="{'open': $store.state.cartOpen, dark: $store.state.settings.darkMode}">
        <Overlay
            :open="$store.state.cartOpen"
            @click="$store.commit('closeCart')"
        />
        <Content class="cart">
            <div class="header">
                <div class="close-button" @click="$store.commit('closeCart')">
                    <img src="@/assets/xmark.svg">
                </div>
                <div class="title">Warenkorb</div>
            </div>

            <div class="cart-empty" v-if="cartEmpty">
                Dein Warenkorb ist leer.
            </div>

            <CartItemList v-else/>

            <div class="cart-footer" v-if="!cartEmpty">
                <div class="totals">
                    <div class="label">
                        Zwischensumme
                    </div>
                    <div class="price">
                        {{ subtotal }}
                    </div>
                    <div v-if="$store.state.cart.shipping_total" class="label">
                        Versand
                    </div>
                    <div v-if="$store.state.cart.shipping_total" class="price">
                        {{ $formatRawPrice($store.state.cart.shipping_total + $store.state.cart.shipping_tax_total) }}
                    </div>
                    <div class="label">
                        Gesamt
                    </div>
                    <div class="price">
                        {{ $formatRawPrice($store.state.cart.total) }}
                    </div>
                    <div v-if="$store.state.cart.tax_total > 0" class="label">
                        Inkl. Steuern
                    </div>
                    <div v-if="$store.state.cart.tax_total > 0" class="price">
                        {{ $formatRawPrice($store.state.cart.tax_total) }}
                    </div>
                </div>
                <div class="actions">
                    <router-link to="/shop" class="button">
                        Weiter Einkaufen
                    </router-link>
                    <router-link to="/shop/checkout" class="button checkout">
                        Zur Kasse
                    </router-link>
                </div>
            </div>
        </Content>
    </div>
</template>

<script>
    import Overlay from '@/components/Overlay'
    import CartItemList from '@/components/CartItemList'
    import Content from '@/components/Content'

    export default {
        name: 'Cart',

        components: {
            Overlay,
            CartItemList,
            Content
        },

        mounted() {
            if(!this.$store.state.regionId){
                this.$store.dispatch('fetchRegion')
            }

            if(this.$store.state.cartId){
                this.$store.dispatch('updateCart')
            }
        },

        computed: {
            cartEmpty() {
                return this.$store.getters.totalCartQty == 0
            },

            subtotal() {
                if(this.$store.state.cart.shipping_total){
                    return this.$formatRawPrice(this.$store.state.cart.total - this.$store.state.cart.shipping_total - this.$store.state.cart.shipping_tax_total)
                } else {
                    return this.$formatRawPrice(this.$store.state.cart.total)
                }
            }
        },

        watch: {
            '$store.state.cartOpen': {
                handler() {
                    this.$setBodyLock()
                }
            },
            '$store.state.errorCounter': {
                handler() {
                    if(this.$store.state.errorCounter > 1){
                        this.$store.dispatch('recreateCart')
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .cart-wrapper {
        display: flex;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: default;
        justify-content: center;
        align-items: center;
        z-index: 989;
        @include transition;
        pointer-events: none;

        @include breakpoint(mobile) {
            align-items: flex-start;
        }

        &.open {
            .cart {
                transform: translateY(0);

                .cart-footer {
                    @include breakpoint(mobile) {
                        box-shadow: $boxShadowS;
                    }
                }
            }
        }

        .cart {
            transform: translateY(-150vh);
            background: white;
            box-shadow: 0 0 60px rgba(0,0,0,0.2);
            padding: 20px;
            margin: 20px;
            max-height: 90vh;
            max-width: 800px;
            display: flex;
            flex-direction: column;
            @include transition;
            justify-content: space-between;
            pointer-events: all;
            z-index: 998;

            @include breakpoint(mobile) {
                transform: translateY(-85vh);
                width: 100vw;
                max-height: 85vh;
                margin: 0;
                padding: 0;
            }

            .cart-empty {
                @include fontSize(m);
                font-weight: 500;
                text-align: center;
                width: 100%;
                padding: 20px 0;
            }

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                border-bottom: 1px solid #999;
                margin-bottom: 20px;
                padding-bottom: 15px;

                @include breakpoint(mobile) {
                    margin-bottom: 0;
                    border-bottom: none;
                    padding: 0 20px;
                    box-shadow: $boxShadowS;
                }

                .title {
                    width: 100%;
                    padding-right: 20px;
                    text-align: center;
                    @include fontSize(xl);
                    font-weight: 700;
                    font-family: $accentFont;
                    flex-grow: 1;

                    @include breakpoint(mobile) {
                        @include fontSize(l);
                        height: 48px;
                        line-height: 48px;
                    }
                }

                .close-button {
                    cursor: pointer;
                    @include transition;

                    &:hover {
                        transform: scale(1.1);
                    }

                    img {
                        height: 32px;
                    }
                }
            }

            .cart-footer {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 20px;
                border-top: 1px solid #999;
                padding-top: 20px;

                @include breakpoint(mobile) {
                    margin-top: 0;
                    padding-top: 10px;
                    border: none;
                    box-shadow: $boxShadowSInvisible;
                }

                .totals {
                    width: 100%;
                    display: grid;
                    box-sizing: border-box;
                    grid-template-columns: 6fr 1fr;
                    grid-column-gap: 20px;
                    border-bottom: 1px solid #999;
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                    padding-right: 10%;

                    @include breakpoint(mobile) {
                        padding-bottom: 10px;
                        padding-right: 0;
                        margin-bottom: 0;
                        grid-template-columns: 1fr 1fr;
                    }

                    .label {
                        @include fontSize(s);
                        text-align: right;
                        font-weight: 500;
                    }

                    .price {
                        @include fontSize(m);
                        text-align: right;
                        font-family: $accentFont;
                        font-weight: 700;

                        @include breakpoint(mobile) {
                            text-align: left;
                        }
                    }
                }

                .actions {
                    padding: 10px;
                    display: flex;

                    @include breakpoint(mobile) {
                        flex-direction: column;
                    }

                    .button {
                        margin: 0 10px;

                        @include breakpoint(mobile) {
                            margin: 10px;
                            text-align: center;
                        }
                    }
                }
            }
        }

        &.dark {
            .cart {
                background: $dark2;

                .header {
                    .close-button {
                        img {
                            filter: invert(1);
                        }
                    }
                }

                .items {
                    @include breakpoint(mobile) {
                        background: #151515;
                    }
                }
            }
        }
    }
</style>
