<template>
    <div id="footer" class="footer" :class="{dark: $store.state.settings.darkMode}">
        <div class="footer-content-wrapper">
            <Content class="footer-content" :class="{'twocol': !$config.enableShop}">
                <div class="links">
                    <div class="footer-list-wrapper">
                        <div class="list-title">Rechtliches</div>
                        <FooterRouterLinks class="link-items"/>
                    </div>
                </div>

                <div class="settings">
                    <div class="footer-list-wrapper">
                        <div class="list-title">Einstellungen</div>
                        <div class="settings-item" @click="$store.commit('openSettings')">
                            <img src="@/assets/gear.svg"/>
                            <span>Allgemein</span>
                        </div>
                        <div class="settings-item" @click="$store.commit('openCookieManager')">
                            <img src="@/assets/cookie.svg"/>
                            <span>Datenschutz</span>
                        </div>
                    </div>
                </div>

                <div class="payments" v-if="$config.enableShop">
                    <div class="footer-list-wrapper">
                        <div class="list-title">Wir akzeptieren</div>
                        <img src="@/assets/paypal_white.png"/>
                        <img src="@/assets/vorkasse.png"/>
                    </div>
                </div>
            </Content>
        </div>
        <div class="copyright">
            <span>© {{ currentYear }} - Stuhrwerk</span>
        </div>
    </div>
</template>

<script>
    import Content from '@/components/Content'
    import FooterRouterLinks from '@/components/FooterRouterLinks'
    import moment from 'moment'

    export default {
        name: 'Footer',

        components: {
            Content,
            FooterRouterLinks
        },

        computed: {
            currentYear() {
                return moment().format('YYYY')
            }
        }
    }
</script>

<style lang="scss">
    .footer {
        color: $white;
        @include fontSize(xs);

        a {
            color: inherit;
            text-decoration: none;

            div {
                color: inherit;
                text-decoration: inherit;
            }

            &:hover {
                div {
                    text-decoration: underline;
                }
            }
        }

        .footer-list-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            > div,
            > img,
            > a {
                margin-bottom: 10px;
            }

            .list-title {
                margin-bottom: 20px;
                font-family: $accentFont;
                @include fontSize(sm);
            }
        }

        .footer-content-wrapper {
            background: rgba(0,0,0,0.85);

            .footer-content {
                display: grid;
                position: relative;
                grid-template-columns: 1fr 1fr 1fr;
                grid-column-gap: 40px;

                &.twocol {
                    grid-template-columns: 1fr 1fr;
                }

                @include breakpoint(mobile) {
                    grid-template-columns: 1fr 1fr;
                    grid-column-gap: 20px;
                    grid-row-gap: 20px;

                    > div {
                        margin-bottom: 20px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .payments {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    height: 100%;

                    @include breakpoint(mobile) {
                        align-items: flex-start;
                        grid-column: 1 / span 2;
                    }

                    img {
                        height: 36px;
                    }

                    .footer-list-wrapper {
                        flex-direction: column;
                        flex-wrap: wrap;

                        @include breakpoint(mobile) {
                            flex-direction: row;
                        }

                        .list-title {
                            width: 100%;
                        }

                        img {
                            margin-bottom: 10px;

                            @include breakpoint(mobile) {
                                margin-right: 10px;
                            }
                        }
                    }
                }

                .links {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    height: 100%;

                    @include breakpoint(mobile) {
                        align-items: flex-start;
                    }

                    .link-items {
                        display: contents;

                        > a {
                            margin-bottom: 10px;
                        }
                    }
                }

                .settings {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;

                    @include breakpoint(mobile) {
                        align-items: flex-start;
                    }

                    .settings-item {
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        &:hover {
                            text-decoration: underline;
                        }

                        img {
                            width: 20px;
                            filter: invert(1);
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        .copyright {
            background: black;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 10px;
            @include fontSize(xxs);

            @include breakpoint(mobile) {
                align-items: center;
            }
        }

        &.dark {
            .footer-content-wrapper {
                background: rgba(0,0,0,0.6);
            }
        }
    }
</style>
