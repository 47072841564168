<template>
    <div class="soundcloud">
        <LoadingPlaceholder/>
        <iframe
            v-if="$store.state.allowEmbeds.soundcloud"
            class="soundcloud-iframe"
            frameborder="no"
            :src="url"
        ></iframe>
        <ForbiddenPlayer v-else embed="soundcloud">
            <p>
                Du hast das Einbinden von SoundCloud Playern auf Stuhrwerk.de verboten. Klicke unten, um diese Einstellung zu ändern.
            </p>
            <p>
                Weitere Informationen zu eingebundenen Inhalten findest du in unserer <router-link to="/privacy" @click.native="$store.commit('closeCookieManager')">Datenschutzerklärung</router-link>.
            </p>
        </ForbiddenPlayer>
    </div>
</template>

<script>
    import ForbiddenPlayer from '@/components/players/ForbiddenPlayer'
    import LoadingPlaceholder from '@/components/LoadingPlaceholder'

    export default {
        name: 'SoundcloudPlayer',

        components: {
            ForbiddenPlayer,
            LoadingPlaceholder
        },

        props: ['soundcloud_url'],

        computed: {
            url() {
                return `https://w.soundcloud.com/player/?url=${this.soundcloud_url}&color=%23231d15&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=true`
            }
        }
    }
</script>

<style lang="scss" scoped>
    .soundcloud {
        box-shadow: 0 0 60px rgba(0,0,0,0.2);
        width: 100%;
        height: 100%;
        position: relative;

        @include breakpoint(mobile) {
            height: 400px;
        }

        .soundcloud-iframe {
            border: 0;
            width: 100%;
            height: 100%;
            position: relative;
        }
    }
</style>
