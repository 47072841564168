export const config = {
    showDrafts: process.env.VUE_APP_SHOW_DRAFTS == 'true' ? true : false,
    enableShop: process.env.VUE_APP_ENABLE_SHOP == 'true' ? true : false,
    baseCms: process.env.VUE_APP_BASE_CMS || 'https://strapiv4.stuhrwerk.de',
    baseStore: process.env.VUE_APP_BASE_STORE || 'https://medusa.stuhrwerk.de',
    paypalClientId: process.env.VUE_APP_PAYPAL_CLIENT_ID || '',
    youtubeApiKey: process.env.VUE_APP_YOUTUBE_API_KEY || '',
    youtubePlaylistId: process.env.VUE_APP_YOUTUBE_PLAYLIST_ID || '',
    meiliUrl: process.env.VUE_APP_MEILI_URL,
    meiliApiKey: process.env.VUE_APP_MEILI_API_KEY,
    matomoHost: process.env.VUE_APP_MATOMO_HOST || 'https://matomo.stuhrblick.de',
    matomoSiteId: process.env.VUE_APP_MATOMO_SITE_ID
}

export const configPlugin = {
    install(Vue) {
        Vue.prototype.$config = config
    }
}
