<template>
    <div>
        <router-link to="/" exact>
            <div class="menu-item">
                Home
            </div>
        </router-link>

        <router-link to="/shop" v-if="$config.enableShop">
            <div class="menu-item">
                Shop
            </div>
        </router-link>

        <router-link to="/artists">
            <div class="menu-item">
                Artists
            </div>
        </router-link>

        <router-link to="/releases">
            <div class="menu-item">
                Releases
            </div>
        </router-link>

        <router-link to="/about">
            <div class="menu-item">
                Über uns
            </div>
        </router-link>

        <router-link to="/contact">
            <div class="menu-item">
                Kontakt / Booking
            </div>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'MainRouterLinks'
    }
</script>
