<template>
    <div
        class="content" 
        :class="{
            'padding-bottom': paddingBottom,
            'padding-top': paddingTop
        }"
    >
        <slot/>
    </div>
</template>

<script>
    export default {
        name: 'Content',

        props: [
            'paddingTop',
            'paddingBottom'
        ]
    }
</script>

<style lang="scss" scoped>
    .content {
        margin: 0 auto;
        padding: 20px;
        width: calc(100vw - 40px);
        max-width: 1200px;

        &.padding-top {
            padding-top: 60px;

            @include breakpoint(mobile) {
                padding-top: 20px;
            }
        }
        &.padding-bottom {
            padding-bottom: 60px;
        }
    }
</style>
