<template>
    <div :class="classes" :style="styles"></div>
</template>

<script>
    export default {
        name: 'LoadingPlaceholder',

        props: {
            loading: {
                type: Boolean,
                default: true
            },
            borderRadius: {
                type: Number,
                default: 0
            }
        },

        computed: {
            classes() {
                return {
                    'loading': this.loading,
                    'dark': this.$store.state.settings.darkMode
                }
            },

            styles() {
                return {
                    borderRadius: `${this.borderRadius}px`
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .loading {
        height: 100%;
        width: 100%;
        position: absolute;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: loading;
        animation-timing-function: linear;
        background: darkgray;
        background: linear-gradient(to right, $light4 0%, $light3 30%, $light4 60%);
        background-size: 200% 100%;

        &.dark {
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: loading;
            animation-timing-function: linear;
            background: linear-gradient(to right, $black 0%, $dark1 30%, $black 60%);
            background-size: 200% 100%;
        }
    }

    @keyframes loading {
        0%{
            background-position-x: 0%;
        }
        100%{
            background-position-x: -200%;
        }
    }
</style>
