<template>
    <div>
        <div v-if="artist && !$apollo.loading">
            <Content :paddingTop="true">
                <PageHeader :title="artist.attributes.title">
                    <template v-if="previousArtistRoute" #left-button>
                        <RevFwButton
                            :to="{
                                name: 'Artist',
                                params: {
                                    url_key: previousArtistRoute
                                }
                            }"
                            direction="rev"
                        />
                    </template>
                    <template v-if="nextArtistRoute" #right-button>
                        <RevFwButton
                            :to="{
                                name: 'Artist',
                                params: {
                                    url_key: nextArtistRoute
                                }
                            }"
                            direction="fw"
                        />
                    </template>
                </PageHeader>

                <div class="artist-content">
                    <Gallery
                        v-if="gallery.length > 0"
                        :gallery="gallery"
                        class="gallery"
                    />
                    <div v-else class="gallery">
                        <img src="@/assets/blank-artist.png"/>
                    </div>

                    <div class="description">
                        <div v-html="$md(artist.attributes.bio)"></div>
                        <LinkIcons class="mt" :links="artist.attributes.links"/>
                        <div v-if="artist.attributes.bookable" class="booking">
                            <a class="button" :href="bookingLink">Booking-Anfrage</a>
                        </div>
                    </div>
                </div>
            </Content>

            <div v-if="availableBlocks && availableBlocks.length > 0">
                <Wrapper
                    v-for="(block, index) in availableBlocks"
                    :key="index"
                    :variant="index % 2 ? null : 'light'"
                >
                    <Content v-if="block == 'releases'">
                        <div class="releases">
                            <h2>Releases</h2>
                            <Grid size="4">
                                <GridItem
                                    v-for="release in releases"
                                    :key="release.id"
                                    :url="`/releases/${release.attributes.url_key}`"
                                    :image="$getReleaseImage(release, 'small')"
                                    :title_one="release.attributes.title"
                                    :title_two="release.attributes.artist"
                                    :title_three="$getArtistContributions(getContributions(release))"
                                    :small="true"
                                    type="cover"
                                />
                            </Grid>
                        </div>
                    </Content>
                    <Content v-else-if="block == 'members'">
                        <h2>Mitglieder</h2>
                        <Grid size="5">
                            <GridItem
                                v-for="object in artist.attributes.artist_connections"
                                :key="object.artist.data.id"
                                :url="`/artists/${object.artist.data.attributes.url_key}`"
                                :image="$getArtistImage(object.artist.data, 'small')"
                                :title_one="object.artist.data.attributes.title"
                                :title_three="$getArtistContributions(object.contributions)"
                                :small="true"
                            />
                        </Grid>
                    </Content>
                    <Content v-else-if="block == 'member_in'">
                        <h2>Mitglied in</h2>
                        <Grid size="5">
                            <GridItem
                                v-for="artist in connectedArtists"
                                :key="artist.id"
                                :url="`/artists/${artist.attributes.url_key}`"
                                :image="$getArtistImage(artist, 'small')"
                                :title_one="artist.attributes.title"
                                :title_three="$getArtistContributions(artist.attributes.artist_connections[0].contributions)"
                                :small="true"
                            />
                        </Grid>
                    </Content>
                    <Content v-else-if="block == 'videos'">
                        <h2>Videos</h2>
                        <Grid :size="$store.state.settings.smallItems ? 3 : 2">
                            <component
                                v-for="(video, i) in artist.attributes.videos"
                                :key="i"
                                :is="$getVideo(video).component"
                                v-bind="$getVideo(video).props"
                            />
                        </Grid>
                    </Content>
                    <Content v-else-if="block == 'events'">
                        <h2>Kommende Events</h2>
                        <div v-if="events && events.length > 0">
                            <Event
                                v-for="event in events"
                                :event="event"
                                :key="event.id"
                            />
                        </div>
                    </Content>
                </Wrapper>
            </div>
        </div>
        <NotFound
            v-if="!artist && !$apollo.loading"
            message="Ups... Artist nicht gefunden!"
        />
        <slot name="footer"/>
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader'
    import Content from '@/components/Content'
    import Wrapper from '@/components/Wrapper'
    import LinkIcons from '@/components/LinkIcons'
    import Gallery from '@/components/Gallery'
    import Grid from '@/components/Grid'
    import GridItem from '@/components/GridItem'
    import Event from '@/components/Event'
    import RevFwButton from '@/components/RevFwButton'
    import NotFound from '@/components/NotFound'
    import moment from 'moment'
    import {
        artistRoutesQuery,
        artistQuery,
        connectedReleasesQuery,
        connectedBandReleasesQuery,
        connectedArtistsQuery,
        connectedEventsQuery
    } from '@/clients/strapi'

    export default {
        name: 'Artist',

        components: {
            PageHeader,
            Content,
            Wrapper,
            LinkIcons,
            Gallery,
            Grid,
            GridItem,
            RevFwButton,
            Event,
            NotFound
        },

        data() {
            return {
                artist: null
            }
        },

        apollo: {
            artistRoutes: {
                query: artistRoutesQuery,
                update: data => data.artists.data
            },

            artist: {
                query: artistQuery,
                variables() {
                    return {
                        url_key: this.$route.params.url_key
                    }
                },
                update: data => data.artists.data[0]
            },

            connectedReleases: {
                query: connectedReleasesQuery,
                variables() {
                    return {
                        artistSlug: this.$route.params.url_key
                    }
                },
                update: data => data.releases.data
            },

            connectedBandReleases: {
                query: connectedBandReleasesQuery,
                variables() {
                    return {
                        artistSlug: this.$route.params.url_key
                    }
                },
                update: data => data.releases.data
            },

            connectedArtists: {
                query: connectedArtistsQuery,
                variables() {
                    return {
                        artistSlug: this.$route.params.url_key
                    }
                },
                update: data => data.artists.data
            },

            events: {
                query: connectedEventsQuery,
                variables() {
                    return {
                        date: moment().format('YYYY-MM-DD'),
                        artistSlug: this.$route.params.url_key
                    }
                },
                update: data => data.events.data
            }

        },

        computed: {
            currentArtistRouteIndex() {
                if(this.artist.attributes){
                    return this.$store.state.artistRoutes.indexOf(this.artist.attributes.url_key)
                }
                return null
            },

            previousArtistRoute() {
                if(this.currentArtistRouteIndex != null && this.currentArtistRouteIndex > 0){
                    return this.$store.state.artistRoutes[this.currentArtistRouteIndex - 1]
                }
                return null
            },

            nextArtistRoute() {
                if(this.currentArtistRouteIndex != null && this.currentArtistRouteIndex < this.$store.state.artistRoutes.length){
                    return this.$store.state.artistRoutes[this.currentArtistRouteIndex + 1]
                }
                return null
            },

            gallery() {
                var gallery = []
                if(this.artist){
                    if(this.artist.attributes.image.data){
                        gallery.push(this.artist.attributes.image.data.attributes)
                    }
                    if(this.artist.attributes.gallery.data){
                        this.artist.attributes.gallery.data.forEach(function(image){
                            gallery.push(image.attributes)
                        })
                    }
                }
                return gallery
            },

            pageTitle() {
                if(this.artist){
                    return this.artist.attributes.title
                }
                else if(this.$apollo.loading) {
                    return '...'
                }
                else {
                    return '404'
                }
            },

            releases() {
                var releases = []
                if(this.connectedReleases && this.connectedReleases.length > 0){
                    this.connectedReleases.forEach(function(release){
                        releases.push(release)
                    })
                }
                if(this.connectedBandReleases && this.connectedBandReleases.length > 0){
                    this.connectedBandReleases.forEach(function(additionalRelease){
                        var releaseExists = false
                        releases.forEach(function(release){
                            if(release.id == additionalRelease.id){
                                releaseExists = true
                            }
                        })
                        if(!releaseExists){
                            releases.push(additionalRelease)
                        }
                    })
                }
                releases.sort(function(a,b){
                    return new Date(b.attributes.release_date) - new Date(a.attributes.release_date)
                })
                return releases
            },

            hasReleases() {
                return (this.connectedReleases && this.connectedReleases.length > 0) ||
                       (this.connectedBandReleases && this.connectedBandReleases.length > 0)
            },

            hasMembers() {
                return this.artist && this.artist.attributes.artist_connections.length > 0
            },

            isMember() {
                return this.connectedArtists && this.connectedArtists.length > 0
            },

            hasVideos() {
                return this.artist && this.artist.attributes.videos && this.artist.attributes.videos.length > 0
            },

            hasEvents() {
                return this.events && this.events.length > 0
            },

            availableBlocks() {
                var blocks = []
                if(this.hasReleases){
                    blocks.push('releases')
                }
                if(this.hasMembers){
                    blocks.push('members')
                }
                if(this.isMember){
                    blocks.push('member_in')
                }
                if(this.hasVideos){
                    blocks.push('videos')
                }
                if(this.hasEvents){
                    blocks.push('events')
                }
                return blocks
            },

            bookingLink() {
                if(this.artist.attributes.bookable){
                    return encodeURI(`mailto:booking@stuhrwerk.de?subject=Booking-Anfrage ${this.artist.attributes.title}`)
                }
                return null
            }
        },

        methods: {
            getContributions(release) {
                if(release.attributes.artist_connections && release.attributes.artist_connections.length > 0){
                    var contributions = []
                    release.attributes.artist_connections.forEach(function(connection){
                        if(connection.contributions && connection.contributions.length > 0){
                            connection.contributions.forEach(function(c){
                                contributions.push(c)
                            })
                        }
                        if(
                            connection.artist &&
                            connection.artist.data &&
                            connection.artist.data.attributes.artist_connections &&
                            connection.artist.data.attributes.artist_connections.length > 0
                        ){
                            connection.artist.data.attributes.artist_connections.forEach(function(secondaryConnection){
                                if(secondaryConnection.contributions && secondaryConnection.contributions.length > 0){
                                    secondaryConnection.contributions.forEach(function(c){
                                        contributions.push(c)
                                    })
                                }
                            })
                        }
                    })
                    return contributions
                }
                return []
            }
        },

        watch: {
            artistRoutes: {
                handler() {
                    var routes = []
                    this.artistRoutes.forEach(function(artist){
                        routes.push(artist.attributes.url_key)
                    })
                    this.$store.commit('setArtistRoutes', routes)
                }
            },

            gallery: {
                handler() {
                    if(this.gallery.length == 0){
                        this.$store.commit('resetPageColors')
                    }
                }
            }
        },

        metaInfo() {
            var title = this.pageTitle
            return {
                title: `● ${title}`
            }
        }
    }
</script>

<style lang="scss" scoped>
    .artist-content {
        display: grid;
        grid-template-columns: calc(50% - 20px) calc(50% - 20px);
        grid-template-areas:
            "gallery description";
        grid-row-gap: 60px;
        grid-column-gap: 40px;
        padding-bottom: 40px;

        @include breakpoint(mobile) {
            grid-template-columns: 100%;
            grid-row-gap: 40px;
            grid-template-areas:
                "gallery"
                "description";
        }

        .gallery {
            grid-area: gallery;
            line-height: 0;

            img {
                width: 100%;
            }
        }

        .description {
            grid-area: description;

            p:last-child {
                margin-bottom: 0;
            }

            .mt {
                margin-top: 30px;
            }
        }

        .booking {
            margin-top: 40px;
        }
    }
</style>
