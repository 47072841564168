<template>
    <div class="zoomable-image">
        <LoadingPlaceholder/>
        <a
            class="image-container"
            :href="$getImageUrl(image.data.attributes.url)"
            :data-pswp-width="image.data.attributes.width"
            :data-pswp-height="image.data.attributes.height"
            target="_blank"
            rel="noreferrer"
        >
            <img v-lazy="$getImageFormatUrl(image, 'small')"/>
        </a>
    </div>
</template>

<script>
    import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm'
    import PhotoSwipe from 'photoswipe/dist/photoswipe.esm'
    import LoadingPlaceholder from '@/components/LoadingPlaceholder'

    export default {
        name: 'ZoomableImage',

        components: {
            LoadingPlaceholder
        },

        props: ['image'],

        data() {
            return {
                lightbox: null
            }
        },

        computed: {
            lightboxOptions() {
                var options = {
                    wheelToZoom: true,
                    maxZoomLevel: 10,
                    gallery: '.zoomable-image .image-container',
                    pswpModule: PhotoSwipe
                }
                if(!this.$store.state.settings.animations){
                    options.showHideAnimationType = 'none'
                }
                return options
            }
        },

        mounted() {
            if(!this.lightbox){
                this.lightbox = new PhotoSwipeLightbox(this.lightboxOptions)
                this.lightbox.init()
            }
        },

        unmounted() {
            if(this.lightbox){
                this.lightbox.destroy()
                this.lightbox = null
            }
        }
    }
</script>

<style lang="scss" scoped>
    .zoomable-image {
        position: relative;
        @include transition;
        cursor: pointer;

        &:hover {
            transform: scale(1.05);
        }

        a {
            width: 100%;
            height: 100%;
            object-fit: cover;
            font-size: 0;
            line-height: 0;
            display: block;
            position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
        }
    }
</style>
