import showdown from 'showdown'
import { format } from 'date-fns'

const converter = new showdown.Converter({
    simpleLineBreaks: true,
    simplifiedAutoLink: true,
    openLinksInNewWindow: true
})

export const hashString = function() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var result = ''
    for(var i = 0; i < 10; i++){
        result += characters.charAt(Math.floor(
            Math.random() * characters.length
        ))
    }
    return result
}

export const prototypesPlugin = {
    install(Vue) {
        Vue.prototype.$formatDateString = function(dateString) {
            return format(new Date(dateString.replace(/ /g,"T")), 'dd.MM.yyyy')
        }
        
        Vue.prototype.$formatDateTimeString = function(dateTimeString) {
            return format(new Date(dateTimeString.replace(/ /g,"T")), 'dd.MM.yyyy - HH:mm:ss')
        }
        
        Vue.prototype.$md = function(text) {
            return converter.makeHtml(text)
        }

        Vue.prototype.$hashString = hashString

        Vue.prototype.$setBodyLock = function() {
            var condition = (
                this.$store.state.cookieManagerOpen ||
                this.$store.state.burgerMenuOpen ||
                this.$store.state.cartOpen ||
                this.$store.state.searchOpen ||
                this.$store.state.settingsOpen
            )
            if(condition){
                document.body.classList.add('lock')
            }
            else {
                document.body.classList.remove('lock')
            }
        }
    }
}
