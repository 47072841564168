<template>
    <div class="not-found">
        <div class="icon">🤷</div>
        <PageHeader :title="message"/>
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader'

    export default {
        name: 'NotFound',

        components: {
            PageHeader
        },

        props: [
            'message'
        ]
    }
</script>

<style lang="scss" scoped>
    .icon {
        width: 100%;
        text-align: center;
        font-size: 120px;
    }
</style>
