<template>
    <div class="gallery-wrapper" :class="classes">
        <div class="rendering-area">
            <LoadingPlaceholder/>
            <Splide
                v-if="gallery.length > 1"
                class="gallery"
                :options="splideOptions"
                @splide:mounted="initSplide"
                ref="splide"
            >
                <SplideSlide
                    v-for="image in gallery"
                    :key="image.url"
                >
                    <a
                        v-if="!disableLightbox"
                        class="gallery-item"
                        :href="permalinks ? image.url : $getImageUrl(image.url)"
                        :data-pswp-width="image.width" 
                        :data-pswp-height="image.height"
                        data-cropped="true"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img v-lazy="permalinks ? getFormat(image) : $getImageUrl(getFormat(image))">
                    </a>
                    <img
                        v-else
                        class="gallery-item"
                        v-lazy="permalinks ? getFormat(image) : $getImageUrl(getFormat(image))"
                    >
                </SplideSlide>
            </Splide>
            <div v-else class="gallery">
                <a
                    v-if="!disableLightbox"
                    class="gallery-item"
                    :href="permalinks ? image.url : $getImageUrl(image.url)"
                    :data-pswp-width="image.width" 
                    :data-pswp-height="image.height"
                    data-cropped="true"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img v-lazy="permalinks ? getFormat(image) : $getImageUrl(getFormat(image))">
                </a>
                <img
                    v-else
                    class="gallery-item"
                    v-lazy="permalinks ? getFormat(image) : $getImageUrl(getFormat(image))"
                >
            </div>
        </div>
    </div>
</template>

<script>
    import { Splide, SplideSlide } from '@splidejs/vue-splide'
    import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm'
    import PhotoSwipe from 'photoswipe/dist/photoswipe.esm'
    import LoadingPlaceholder from '@/components/LoadingPlaceholder'
    import extractColors from 'extract-colors'

    import 'photoswipe/dist/photoswipe.css'

    export default {
        name: 'Gallery',

        components: {
            Splide,
            SplideSlide,
            LoadingPlaceholder
        },

        props: [
            'gallery',
            'permalinks',
            'disableLightbox',
            'type'
        ],

        data() {
            return {
                splideOptions: {
                    rewind: true,
                    lazyLoad: true
                },

                extractColorsConfig: {
                    pixels: 100,
                    crossOrigin: 'Anonymous',
                    saturationImportance: 0.3,
                    colorDistance: 0.3
                },

                splide: null,
                lightbox: null,

                currentIndex: 0,
                splideList: null
            }
        },

        computed: {
            lightboxOptions() {
                var options = {
                    wheelToZoom: true,
                    maxZoomLevel: 10,
                    loop: true,
                    arrowPrev: false,
                    arrowNext: false,
                    gallery: '.gallery',
                    children: '.gallery-item',
                    pswpModule: PhotoSwipe
                }
                if(!this.$store.state.settings.animations){
                    options.showHideAnimationType = 'none'
                }
                return options
            },

            imageSources() {
                var self = this
                var sources = []
                this.gallery.forEach(function(image){
                    sources.push({
                        src: self.permalinks ?
                            self.getFormat(image) :
                            self.$getImageUrl(self.getFormat(image))
                    })
                })
                return sources
            },

            image() {
                return this.gallery.length ? this.gallery[0] : null
            },

            classes() {
                var classes = {
                    'disable-lightbox': this.disableLightbox ? true : false
                }
                if(this.type && this.type == 'cover'){
                    classes[this.type] = true
                }
                return classes
            },

            transform() {
                return `translateX(-${this.currentIndex * 100}%)`
            }
        },

        methods: {
            initSplide(splide) {
                var self = this
                this.splide = splide
                this.splide.on('move', function(newIndex){
                    self.currentIndex = newIndex
                })
                this.splideList = this.$refs.splide.$el.getElementsByClassName('splide__list')[0]
                this.splide.on('destroy', function(){
                    self.setTransform()
                })
            },

            getFormat(image) {
                if(image.formats && image.formats.medium){
                    return image.formats.medium.url
                } else {
                    return image.url
                }
            },

            setTransform() {
                this.splideList.style.transform = this.transform
            },

            extractColorsForBackground() {
                if(this.$store.state.settings.pageBackground){
                    var self = this
                    var url = this.getFormat(this.image)
                    var image = url
                    if(!url.includes('http')){
                        image = this.$getImageUrl(url)
                    }
                    extractColors(image, this.extractColorsConfig)
                    .then(function(result){
                        self.$store.commit('setPageColors', result)
                    })
                }
            }
        },

        mounted() {
            var self = this
            if(!this.lightbox && !this.disableLightbox){
                this.lightbox = new PhotoSwipeLightbox(this.lightboxOptions)
                if(this.gallery.length > 1){
                    this.lightbox.on('change', function(){
                        self.splide.go(self.lightbox.pswp.currIndex)
                    })
                }
                this.lightbox.init()
            }

            this.extractColorsForBackground()
        },

        unmounted() {
            if(this.lightbox){
                this.lightbox.destroy()
                this.lightbox = null
            }
        },

        watch: {
            '$store.state.settings.pageBackground': {
                handler() {
                    this.extractColorsForBackground()
                }
            }
        }
    }
</script>

<style lang="scss">
    .gallery-wrapper {
        &.disable-lightbox {
            .gallery {
                cursor: default;
                height: 100%;

                .gallery-item {
                    cursor: default;
                }
            }
        }

        .rendering-area {
            background: transparent;
            height: 0;
            position: relative;
            padding-bottom: 120%;
            width: 100%;

            .gallery {
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                object-fit: cover;
                box-shadow: 0 0 60px rgba(0,0,0,0.2);
                cursor: zoom-in;

                .gallery-item {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    font-size: 0;
                    line-height: 0;
                    display: block;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .splide__track,
                .splide__list {
                    width: 100%;
                    height: 100%;
                    display: flex;

                    .splide__slide {
                        cursor: zoom-in;
                        width: 100%;
                        height: 100%;
                    }
                }

                .splide__arrow,
                .splide__pagination {
                    opacity: 0;
                    @include transition;

                    .splide__pagination__page {
                        background: #666;

                        &.is-active {
                            background: #ccc;
                        }
                    }
                }
            }
        }

        &.cover {
            .rendering-area {
                padding-bottom: 100%;
            }
        }

        &:hover {
            .rendering-area {
                .gallery {
                    .splide__arrow,
                    .splide__pagination {
                        opacity: 1;
                    }
                }
            }
        }
    }
</style>
