<template>
    <div class="header" :class="{'slotless': slotless}">
        <div v-if="!slotless" class="left-button">
            <slot name="left-button"/>
        </div>
        <div class="text">
            <h1 v-if="title" class="title">
                {{ title }}
            </h1>
            <h2 v-if="subtitle" class="subtitle">
                {{ subtitle }}
            </h2>
        </div>
        <div v-if="!slotless" class="right-button">
            <slot name="right-button"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageHeader',

        props: [
            'title',
            'subtitle'
        ],

        computed: {
            slotless() {
                return (
                    !this.$slots['left-button'] &&
                    !this.$slots['right-button']
                )
            }
        }
    }
</script>

<style lang="scss" scoped>
    .header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        &.slotless {
            justify-content: center;
        }

        .text {
            flex-shrink: 1;
            margin-bottom: 40px;

            @include breakpoint(mobile) {
                margin-bottom: 20px;
            }
        }

        .left-button,
        .right-button {
            width: 32px;
            display: flex;
        }

        .left-button {
            justify-content: flex-start;
        }

        .right-button {
            justify-content: flex-end;
        }
    }

    .title,
    .subtitle {
        padding: 0 15px;
        margin-bottom: 0;
    }

    .title {
        position: relative;
    }

    .subtitle {
        font-family: $primaryFont;
        font-weight: 500;
        text-align: center;
        @include fontSize(l);

        @include breakpoint(mobile) {
            @include fontSize(sm);
        }
    }
</style>
