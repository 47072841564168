<template>
    <div
        class="wrapper"
        :class="{'open': $store.state.searchOpen}"
    >
        <Overlay
            :open="$store.state.searchOpen"
            @click="$store.commit('closeSearch')"
        />

        <div class="search" :class="{dark: $store.state.settings.darkMode}">
            <div class="header">
                <div
                    class="close-button"
                    @click="$store.commit('closeSearch')"
                >
                    <img src="@/assets/xmark.svg">
                </div>
                <div class="search-bar">
                    <input
                        type="text"
                        spellcheck="false"
                        v-model="query"
                        ref="input"
                        autofocus
                    >
                    <div class="clear">
                        <img src="@/assets/circle-x.svg" @click="clearQuery">
                    </div>
                </div>
            </div>
            <CollapseTransition :duration="150">
                <div class="results-wrapper" v-show="totalResults != null">
                    <div class="results">
                        <div class="section" v-if="query && totalResults == 0">
                            <NotFound message="Nichts gefunden..."/>
                        </div>
                        <div
                            class="section"
                            v-if="results.artists && results.artists.length > 0"
                        >
                            <div class="section-title">
                                Artists
                            </div>
                            <ArtistResults :results="results.artists"/>
                        </div>
                        <div
                            class="section"
                            v-if="results.releases && results.releases.length > 0"
                        >
                            <div class="section-title">
                                Releases
                            </div>
                            <ReleaseResults :results="results.releases"/>
                        </div>
                        <!--<div
                            class="section"
                            v-if="results.events && results.events.length > 0"
                        >
                            <div class="section-title">
                                Events
                            </div>
                            <EventResults :results="results.events"/>
                        </div>-->
                    </div>
                </div>
            </CollapseTransition>
        </div>
    </div>
</template>

<script>
    import Overlay from '@/components/Overlay'
    import { CollapseTransition } from '@ivanv/vue-collapse-transition'
    import NotFound from '@/components/NotFound'
    import ArtistResults from '@/components/search-results/ArtistResults'
    import ReleaseResults from '@/components/search-results/ReleaseResults'
    /* import EventResults from '@/components/search-results/EventResults' */

    export default {
        name: 'Search',

        components: {
            Overlay,
            CollapseTransition,
            NotFound,
            ArtistResults,
            ReleaseResults,
            /* EventResults */
        },

        data() {
            return {
                artistSettings: {
                    limit: 6,
                    attributesToRetrieve: [
                        'id',
                        'title',
                        'url_key',
                        'bio',
                        'image'
                    ]
                },
                releaseSettings: {
                    limit: 6,
                    attributesToRetrieve: [
                        'id',
                        'title',
                        'artist',
                        'url_key',
                        'description',
                        'cover'
                    ]
                },
                /* eventSettings: { */
                /*     limit: 6, */
                /*     attributesToRetrieve: [ */
                /*         'id', */
                /*         'title', */
                /*         'url_key', */
                /*         'city', */
                /*         'country', */
                /*         'venue', */
                /*         'description', */
                /*         'flyer', */
                /*         'date' */
                /*     ] */
                /* }, */
                query: null,
                results: {
                    artists: null,
                    releases: null,
                    /* events: null */
                }
            }
        },

        computed: {
            totalResults() {
                if(
                    this.results.artists &&
                    this.results.releases
                    /* this.results.events */
                ){
                    return (
                        this.results.artists.length +
                        this.results.releases.length
                        /* this.results.events.length */
                    )
                }
                else {
                    return null
                }
            }
        },

        mounted() {
            var self = this
            this.$store.commit('clearSearchQuery')
            window.addEventListener('keydown', function(e){
                if(e.key == 'Escape' && self.$store.state.searchOpen){
                    self.$store.commit('closeSearch')
                }
            })
        },

        methods: {
            search(index, settings, target) {
                var self = this
                index.search(this.query, settings)
                .then(function(res){
                    if(res.query == self.query){
                        self.results[target] = res.hits
                    }
                })
            },

            clearResults() {
                this.results.artists = null
                this.results.releases = null
                /* this.results.events = null */
            },

            clearQuery() {
                if(!this.query){
                    this.$store.commit('closeSearch')
                }
                else {
                    this.query = null
                    this.$refs.input.focus()
                }
            }
        },

        watch: {
            '$store.state.searchOpen': {
                handler() {
                    this.$setBodyLock()
                    if(this.$store.state.searchOpen){
                        var self = this
                        setTimeout(function(){
                            self.$refs.input.click()
                            self.$refs.input.focus()
                        }, 250)
                    }
                    else {
                        this.$refs.input.blur()
                    }
                }
            },

            query() {
                this.$store.commit('setSearchQuery', this.query)
                if(this.query){
                    this.search(this.$artistIndex, this.artistSettings, 'artists')
                    this.search(this.$releaseIndex, this.releaseSettings, 'releases')
                    /* this.search(this.$eventIndex, this.eventSettings, 'events') */
                }
                else {
                    this.clearResults()
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        pointer-events: none;
        z-index: 990;

        &.open {
            .search {
                @include transition;
                transform: translateY(0);
            }
        }

        .search {
            transform: translateY(-150vh);
            @include transition;
            width: 100%;
            max-width: 1160px;
            background: $white;
            display: flex;
            flex-direction: column;
            z-index: 999;
            pointer-events: all;
            margin: 20px;
            max-height: calc(100% - 40px);

            @include breakpoint(mobile) {
                margin: 0;
                width: 100%;
                max-height: 100%;
            }

            .header {
                flex-grow: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                padding: 20px;

                .close-button {
                    cursor: pointer;
                    @include transition;
                    margin-right: 20px;

                    &:hover {
                        transform: scale(1.1);
                    }

                    @include mobileClick;

                    img {
                        height: 32px;
                    }
                }

                .search-bar {
                    flex-grow: 1;
                    display: flex;
                    position: relative;

                    input {
                        margin: 0;
                        flex-grow: 1;
                        border: none;
                        height: 40px;
                        border-radius: 40px;
                        -webkit-appearance: none;
                        box-shadow: inset 0 0 20px rgba(0,0,0,0.2);
                        @include fontSize(l);
                        line-height: 40px;
                        padding: 10px 20px;
                        padding-bottom: 13px;
                        padding-right: 55px;
                        font-weight: 500;

                        @include breakpoint(mobile) {
                            @include fontSize(sm);
                            height: 30px;
                            border-radius: 30px;
                            line-height: 30px;
                            padding-right: 50px;
                        }
                    }

                    .clear {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 18px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @include breakpoint(mobile) {
                            right: 16px;
                        }

                        img {
                            height: 40%;
                            opacity: 0.5;
                            cursor: pointer;
                            @include transition;

                            &:hover {
                                transform: scale(1.1);
                            }

                            @include mobileClick;
                        }
                    }
                }
            }

            .results-wrapper {
                flex-grow: 1;
                display: flex;
                overflow-y: scroll;

                .results {
                    flex-grow: 1;
                    padding: 20px;

                    .section {
                        margin-bottom: 20px;

                        .section-title {
                            @include fontSize(l);
                            font-family: $accentFont;
                            font-weight: 700;
                            margin-bottom: 20px;
                        }
                    }
                }
            }

            &.dark {
                background: $dark2;

                .header {
                    .close-button {
                        img {
                            filter: invert(1);
                        }
                    }

                    .search-bar {
                        input {
                            background: $dark1;
                            color: $light3;
                        }

                        .clear {
                            img {
                                filter: invert(1);
                            }
                        }
                    }
                }
            }
        }
    }
</style>
