<template>
    <div>
        <div v-if="release && !$apollo.loading">
            <Content :paddingTop="true">
                <PageHeader
                    :title="release.attributes.title"
                    :subtitle="release.attributes.artist"
                >
                    <template v-if="previousReleaseRoute" #left-button>
                        <RevFwButton
                            :to="{
                                name: 'Release',
                                params: {
                                    url_key: previousReleaseRoute
                                }
                            }"
                            direction="rev"
                            @click.native="products = []"
                        />
                    </template>
                    <template v-if="nextReleaseRoute" #right-button>
                        <RevFwButton
                            :to="{
                                name: 'Release',
                                params: {
                                    url_key: nextReleaseRoute
                                }
                            }"
                            direction="fw"
                            @click.native="products = []"
                        />
                    </template>
                </PageHeader>

                <div class="release-content" :class="{'no-player': noPlayerBlock}">
                    <Gallery
                        v-if="gallery.length > 0"
                        :gallery="gallery"
                        class="gallery"
                        type="cover"
                    />
                    <div v-else class="gallery">
                        <img src="@/assets/blank-release.png"/>
                    </div>
                    
                    <component
                        v-if="release.attributes.players && release.attributes.players.length"
                        :is="$getPlayer(release.attributes.players[0]).component"
                        v-bind="$getPlayer(release.attributes.players[0]).props"
                    />

                    <TrackList
                        v-else-if="release.attributes.track_list && release.attributes.track_list.length > 0"
                        :trackList="release.attributes.track_list"
                    />

                    <div v-if="!noPlayerBlock && release.attributes.description" class="description">
                        <div v-html="$md(release.attributes.description)"></div>
                    </div>

                    <div v-if="!noPlayerBlock" class="additional">
                        <LinkIcons class="mb" :links="release.attributes.links"/>

                        <h2 class="date">
                            Release: {{ $formatDateString(release.attributes.release_date) }}
                        </h2>

                        <CatalogNumbers
                            v-if="release.attributes.catalog_numbers.length > 0"
                            :catalogNumbers="release.attributes.catalog_numbers"
                        />
                    </div>

                    <div v-if="noPlayerBlock" class="combined">
                        <div class="description" v-if="release.attributes.description">
                            <div v-html="$md(release.attributes.description)"></div>
                        </div>

                        <div class="additional">
                            <LinkIcons class="mb" :links="release.attributes.links"/>

                            <h2 class="date">
                                Release: {{ $formatDateString(release.attributes.release_date) }}
                            </h2>

                            <CatalogNumbers
                                v-if="release.attributes.catalog_numbers.length > 0"
                                :catalogNumbers="release.attributes.catalog_numbers"
                            />
                        </div>
                    </div>
                </div>
            </Content>

            <div v-if="availableBlocks && availableBlocks.length > 0">
                <Wrapper
                    v-for="(block, index) in availableBlocks"
                    :key="index"
                    :variant="index % 2 ? null : 'light'"
                >
                    <Content v-if="block == 'artists'">
                        <h2>Artists</h2>
                        <Grid size="5">
                            <GridItem
                                v-for="object in validArtistConnections"
                                :key="object.artist.data.id"
                                :url="`/artists/${object.artist.data.attributes.url_key}`"
                                :image="$getArtistImage(object.artist.data, 'small')"
                                :title_one="object.artist.data.attributes.title"
                                :title_three="$getArtistContributions(object.contributions)"
                                :small="true"
                            />
                        </Grid>
                    </Content>
                    <Content v-else-if="block == 'shop'">
                        <h2>Produkte im Shop</h2>
                        <Grid>
                            <GridItem
                                v-for="product in products"
                                :key="product.id"
                                :url="`/shop/product/${product.handle}`"
                                :image="product.thumbnail"
                                :title_one="product.title"
                                :title_two="$formatPrice(product)"
                            />
                        </Grid>
                    </Content>
                    <Content v-else-if="block == 'videos'">
                        <h2>Videos</h2>
                        <Grid size="2">
                            <component
                                v-for="(video, i) in release.attributes.videos"
                                :key="i"
                                :is="$getVideo(video).component"
                                v-bind="$getVideo(video).props"
                            />
                        </Grid>
                    </Content>
                </Wrapper>
            </div>
        </div>
        <NotFound
            v-if="!release && !$apollo.loading"
            message="Ups... Release nicht gefunden!"
        />
        <slot name="footer"/>
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader'
    import Content from '@/components/Content'
    import Wrapper from '@/components/Wrapper'
    import LinkIcons from '@/components/LinkIcons'
    import Gallery from '@/components/Gallery'
    import Grid from '@/components/Grid'
    import GridItem from '@/components/GridItem'
    import RevFwButton from '@/components/RevFwButton'
    import TrackList from '@/components/TrackList'
    import CatalogNumbers from '@/components/CatalogNumbers'
    import NotFound from '@/components/NotFound'
    import {
        releaseRoutesQuery,
        releaseQuery
    } from '@/clients/strapi'

    export default {
        name: 'Release',

        components: {
            PageHeader,
            Content,
            Wrapper,
            LinkIcons,
            Gallery,
            Grid,
            GridItem,
            RevFwButton,
            TrackList,
            CatalogNumbers,
            NotFound
        },

        data() {
            return {
                release: null,
                products: []
            }
        },

        apollo: {
            releaseRoutes: {
                query: releaseRoutesQuery,
                update: data => data.releases.data
            },

            release: {
                query: releaseQuery,
                variables() {
                    return {
                        url_key: this.$route.params.url_key
                    }
                },
                update: data => data.releases.data[0]
            }
        },

        computed: {
            currentReleaseRouteIndex() {
                if(this.release.attributes){
                    return this.$store.state.releaseRoutes.indexOf(this.release.attributes.url_key)
                }
                return null
            },

            previousReleaseRoute() {
                if(this.currentReleaseRouteIndex != null && this.currentReleaseRouteIndex > 0){
                    return this.$store.state.releaseRoutes[this.currentReleaseRouteIndex - 1]
                }
                return null
            },

            nextReleaseRoute() {
                if(this.currentReleaseRouteIndex != null && this.currentReleaseRouteIndex < this.$store.state.releaseRoutes.length){
                    return this.$store.state.releaseRoutes[this.currentReleaseRouteIndex + 1]
                }
                return null
            },

            gallery() {
                var gallery = []
                if(this.release){
                    if(this.release.attributes.cover.data){
                        gallery.push(this.release.attributes.cover.data.attributes)
                    }
                    if(this.release.attributes.gallery.data){
                        this.release.attributes.gallery.data.forEach(function(image){
                            gallery.push(image.attributes)
                        })
                    }
                }
                return gallery
            },

            productsExist() {
                if(this.release){
                    return this.release.attributes.products.length > 0
                }
                else {
                    return null
                }
            },

            pageTitle() {
                if(this.release){
                    return `${this.release.attributes.title} - ${this.release.attributes.artist}`
                }
                else if(this.$apollo.loading) {
                    return '...'
                }
                else {
                    return '404'
                }
            },

            noPlayerBlock() {
                return (
                    this.release.attributes.players.length == 0 &&
                    this.release.attributes.track_list.length == 0
                )
            },

            availableBlocks() {
                var blocks = []
                if(this.release && this.release.attributes.artist_connections.length > 0){
                    blocks.push('artists')
                }
                if(this.$config.enableShop && this.products && this.products.length > 0){
                    blocks.push('shop')
                }
                if(this.release && this.release.attributes.videos && this.release.attributes.videos.length > 0){
                    blocks.push('videos')
                }
                return blocks
            },

            validArtistConnections() {
                var connections = []
                if(this.release.attributes.artist_connections){
                    this.release.attributes.artist_connections.forEach(function(conn){
                        if(conn.artist.data){
                            connections.push(conn)
                        }
                    })
                }
                return connections
            },
        },

        methods: {
            fetchProducts() {
                var self = this
                if(
                    this.productsExist &&
                    this.$config.enableShop &&
                    this.$store.state.regionId
                ){
                    this.products = []
                    this.release.attributes.products.forEach(function(product){
                        self.$medusa.products.list({
                            region_id: self.$store.state.regionId,
                            handle: product.shop_handle
                        })
                        .then(function(res){
                            if(res.products.length > 0){
                                self.products.push(res.products[0])
                            }
                        })
                    })
                }
            }
        },

        watch: {
            releaseRoutes: {
                handler() {
                    var routes = []
                    this.releaseRoutes.forEach(function(release){
                        routes.push(release.attributes.url_key)
                    })
                    this.$store.commit('setReleaseRoutes', routes)
                }
            },

            gallery: {
                handler() {
                    if(this.gallery.length == 0){
                        this.$store.commit('resetPageColors')
                    }
                }
            },

            productsExist: {
                handler() {
                    this.fetchProducts()
                }
            },

            '$store.state.regionId': {
                handler() {
                    this.fetchProducts()
                }
            }
        },

        metaInfo() {
            var title = this.pageTitle
            return {
                title: `● ${title}`
            }
        }
    }
</script>

<style lang="scss" scoped>
    .release-content {
        display: grid;
        grid-template-columns: calc(50% - 20px) calc(50% - 20px);
        grid-template-areas:
            "gallery music"
            "description additional";
        grid-row-gap: 60px;
        grid-column-gap: 40px;
        padding-bottom: 40px;

        @include breakpoint(mobile) {
            margin-bottom: 20px;
            padding-bottom: 0;
            grid-template-columns: 100%;
            grid-row-gap: 20px;
            grid-template-areas:
                "gallery"
                "music"
                "description"
                "additional";
        }

        &.no-player {
            grid-template-areas:
                "gallery combined";

            @include breakpoint(mobile) {
                grid-template-areas:
                    "gallery"
                    "combined";
            }

            .combined {
                grid-area: combined;

                .description {
                    margin-bottom: 30px;
                }
            }
        }

        .gallery {
            grid-area: gallery;
            line-height: 0;

            img {
                width: 100%;
            }
        }

        .music {
            grid-area: music;
        }

        .description {
            grid-area: description;
        }

        .additional {
            grid-area: additional;

            .date {
                text-align: left;
            }

            .mb {
                margin-bottom: 20px;
            }
        }

        .shop {
            grid-area: shop;
        }
    }
</style>
