<template>
    <div class="toggle" :class="classes" @click="toggle">
        <div class="slider">
            <span class="label" v-if="modelValue">
                {{ onLabel }}
            </span>
            <span class="label" v-else>
                {{ offLabel }}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Toggle',

        props: [
            'modelValue',
            'onLabel',
            'offLabel',
            'disabled'
        ],

        model: {
            prop: 'modelValue',
            event: 'update'
        },

        emits: ['update'],

        computed: {
            classes() {
                return {
                    checked: this.modelValue,
                    disabled: this.disabled
                }
            }
        },

        methods: {
            toggle() {
                this.$emit('update', !this.modelValue)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .toggle {
        position: relative;
        display: inline-block;
        width: 140px;
        height: 34px;

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $red;
            @include transition;
            border-radius: 34px;

            .label {
                font-family: $accentFont;
                @include fontSize(s);
                color: white;
                font-weight: 500;
                line-height: 36px;
                width: 100%;
                display: block;
                text-align: center;
            }

            &:before {
                position: absolute;
                content: "";
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 4px;
                background-color: white;
                @include transition;
                border-radius: 50%;
            }
        }

        &.checked {
            .slider {
                background-color: $green;

                &:before {
                    transform: translateX(106px);
                }
            }
        }

        &.disabled {
            .slider {
                background-color: $dark3;
            }
        }
    }
</style>
