<template>
    <div class="color-bg" :style="{background: colorBg}"></div>
</template>

<script>
    import chroma from "chroma-js"

    export default {
        name: 'AutoColorBg',

        computed: {
            colorBg() {
                if(this.$store.state.settings.pageBackground){
                    if(this.$store.state.pageColors.length > 0) {
                        var hex = this.$store.state.pageColors[0].hex
                        var hsl;
                        if(!this.$store.state.settings.darkMode){
                            if(chroma(hex).hsl()[2] < 0.001 && this.$store.state.pageColors.length > 1){
                                hex = this.$store.state.pageColors[1].hex
                            }
                            hsl = chroma(hex).hsl()
                            if(hsl[1] > 0.1 && hsl[2] > 0.8){
                                if(hsl[1] <= 0.3){
                                    return hex;
                                }
                                hsl[1] = 0.3
                                return chroma.hsl(hsl).hex()
                            }
                            else if(hsl[1] > 0.1) {
                                hsl[2] = 0.8
                                if(hsl[1] <= 0.3){
                                    return chroma.hsl(hsl).hex()
                                }
                                hsl[1] = 0.3
                                return chroma.hsl(hsl).hex()
                            }
                        }
                        else {
                            if(chroma(hex).hsl()[2] < 0.001 && this.$store.state.pageColors.length > 1){
                                hex = this.$store.state.pageColors[1].hex
                            }
                            hsl = chroma(hex).hsl()
                            hsl[2] = 0.1
                            if(hsl[1] <= 0.7){
                                return chroma.hsl(hsl).hex();
                            }
                            hsl[1] = 0.7
                            return chroma.hsl(hsl).hex()
                        }
                    }
                }
                if(!this.$store.state.settings.darkMode){
                    return '#fff'
                }
                else {
                    return '#151515'
                }
            }
        },

        mounted() {
            document.body.style.background = this.colorBg
        },

        watch: {
            colorBg: {
                handler() {
                    document.body.style.background = this.colorBg
                }
            }
        },

        metaInfo() {
            var bg = this.colorBg
            return {
                meta: [{
                    name: 'theme-color',
                    content: bg
                }]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .color-bg {
        position: fixed !important;
        width: 100vw;
        height: 100vh;
        transition: 1s;
    }

</style>
