<template>
    <div class="wrapper" :class="classes">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: 'Wrapper',

        props: [
            'variant',
            'expand'
        ],

        computed: {
            classes() {
                var classes = {
                    expand: this.expand ? true : false,
                    'dark-mode': this.$store.state.settings.darkMode
                }
                classes[this.variant] = true
                return classes
            }
        }
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        padding: 30px 0;

        @include breakpoint('mobile') {
            padding: 20px 0;
        }
    }

    .light {
        /* background: $light4; */
        background: rgba(0,0,0,0.08);

        &.dark-mode {
            background: rgba(0,0,0,0.4);
        }
    }

    .dark {
        /* background: $light3; */
        background: rgba(0,0,0,0.25);

        &.dark-mode {
            background: rgba(0,0,0,0.6);
        }
    }

    .expand {
        flex-grow: 1;
    }
</style>
