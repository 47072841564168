<template>
    <div class="event" :class="classes">
        <div class="main" @click="toggle">
            <div class="dot-wrapper">
                <img
                    v-if="anyContent"
                    src="@/assets/circle-arrow.svg"
                    class="dot arrow"
                    :class="{'open': open}"
                >
                <img
                    v-else
                    src="@/assets/circle-dot.svg"
                    class="dot circle"
                >
            </div>
            <div class="title">
                <div class="first-line">
                    {{ event.attributes.title }}
                </div>
                <div
                    v-if="event.attributes.city || event.attributes.country"
                    class="second-line"
                >
                    <span v-if="event.attributes.city">
                        {{ event.attributes.city }}
                    </span>
                    <span v-if="event.attributes.country">
                        ({{ event.attributes.country }})
                    </span>
                </div>
            </div>
            <div class="start">
                {{ $formatDateString(event.attributes.date) }}
            </div>
        </div>
        <CollapseTransition v-if="anyContent">
            <div class="body" v-show="open">
                <div class="description">
                    <div
                        v-if="event.attributes.flyer && event.attributes.flyer.data"
                        class="flyer"
                    >
                        <ZoomableImage
                            class="image"
                            :image="event.attributes.flyer"
                        />
                    </div>
                    <div
                        v-if="event.attributes.description"
                        v-html="$md(event.attributes.description)"
                        class="text markdown"
                    ></div>
                    <div
                        class="artists"
                        v-if="event.attributes.artists && event.attributes.artists.data.length > 0"
                    >
                        <div class="title">Mit dabei:</div>
                        <Grid size="6">
                            <GridItem
                                v-for="artist in event.attributes.artists.data"
                                :key="artist.id"
                                :url="`/artists/${artist.attributes.url_key}`"
                                :image="$getArtistImage(artist, 'small')"
                                :title_one="artist.attributes.title"
                                :small="true"
                            />
                        </Grid>
                    </div>
                </div>
            </div>
        </CollapseTransition>
    </div>
</template>

<script>
    import Grid from '@/components/Grid'
    import GridItem from '@/components/GridItem'
    import ZoomableImage from '@/components/ZoomableImage'
    import { CollapseTransition } from '@ivanv/vue-collapse-transition'

    export default {
        name: 'Event',

        components: {
            Grid,
            GridItem,
            CollapseTransition,
            ZoomableImage
        },

        props: ['event'],

        data() {
            return {
                open: false
            }
        },

        computed: {
            anyContent() {
                return ((
                    this.event.attributes.flyer &&
                    this.event.attributes.flyer.data
                ) || (
                    this.event.attributes.artists &&
                    this.event.attributes.artists.data.length > 0
                ) ||
                    this.event.attributes.description
                )
            },

            classes() {
                return {
                    'no-content': !this.anyContent,
                    dark: this.$store.state.settings.darkMode
                }
            }
        },

        methods: {
            toggle() {
                if(this.anyContent){
                    this.open = !this.open
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .event {
        width: 100%;
        border-bottom: 1px solid rgba(0,0,0,0.2);

        &.no-content {
            .main {
                cursor: default;

                &:hover {
                    .dot-wrapper {
                        transform: none;
                    }
                }
            }
        }

        .main {
            display: flex;
            @include fontSize(m);
            padding: 20px 0;
            align-items: center;
            cursor: pointer;
            @include transition;

            @include breakpoint('mobile') {
                @include fontSize(s);
            }

            &:hover {
                .dot-wrapper {
                    transform: scale(1.2);
                }
            }

            .title {
                flex-grow: 1;

                .second-line {
                    font-weight: 500;
                }
            }

            .start {
                font-weight: 500;
                margin-left: 20px;
            }

            .dot-wrapper {
                @include transition;

                .dot {
                    width: 8px;
                    margin-right: 10px;
                    padding: 6px;

                    &.arrow {
                        width: 20px;
                        padding: 0;
                        transform: rotate(-90deg);
                        @include transition;

                        &.open {
                            transform: rotate(0deg);
                        }
                    }

                    &.circle {
                        width: 20px;
                        padding: 0;
                    }
                }
            }
        }

        .body {
            width: 100%;
            @include fontSize(xs);

            .description {
                padding: 20px 30px;
                padding-top: 0;

                .text {
                    margin-bottom: 30px;
                }

                .artists {
                    .title {
                        font-weight: 700;
                        margin-bottom: 10px;
                    }
                }

                .flyer {
                    margin-bottom: 20px;

                    .image {
                        width: 250px;

                        @include breakpoint(mobile) {
                            width: 100%;
                        }
                    }
                }
            }
        }

        &.dark {
            border-bottom: 1px solid rgba(255,255,255,0.3);

            .dot-wrapper {
                img {
                    filter: invert(1);
                }
            }
        }
    }
</style>
