import Vue from 'vue'

Vue.config.devtools = true

import App from '@/App'
import VueLazyload from 'vue-lazyload'
import VueMeta from 'vue-meta'
import VueMatomo from 'vue-matomo'
import router from '@/router'
import { config, configPlugin } from '@/config'
import { prototypesPlugin } from '@/prototypes'
import { medusaPlugin } from '@/clients/medusa'
import { paypalPlugin } from '@/clients/paypal'
import { youtubePlugin } from '@/clients/youtube'
import { strapiProvider } from '@/clients/strapi'
import { meiliPlugin } from '@/clients/meili'
import { storePlugin } from '@/store'

Vue.use(VueLazyload, {
    preload: 2
})

Vue.use(VueMeta)

Vue.use(VueMatomo, {
    host: config.matomoHost,
    siteId: config.matomoSiteId,
    trackerFileName: 'matomo',
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: true,
    enableHeartBeatTimer: true,
    heartBeatTimerInterval: 15,
    debug: false,
    userId: undefined,
    cookieDomain: undefined,
    domains: undefined,
    preInitActions: [],
    router: router
})

Vue.use(configPlugin)
Vue.use(prototypesPlugin)
if(config.enableShop){
    Vue.use(medusaPlugin)
}
Vue.use(paypalPlugin)
Vue.use(youtubePlugin)
Vue.use(meiliPlugin)
Vue.use(storePlugin)

Vue.config.productionTip = false

new Vue({
    router,
    apolloProvider: strapiProvider,
    render: h => h(App)
}).$mount('#app')
