import Medusa from "@medusajs/medusa-js"
import { config } from '@/config'

export const medusa = new Medusa({
    maxRetries: 3,
    baseUrl: config.baseStore
})

export const medusaPlugin = {
    install(Vue) {
        Vue.prototype.$medusa = medusa

        Vue.prototype.$formatPrice = function(product) {
            var price = product.variants[0].calculated_price_incl_tax
            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price / 100)
        }
        
        Vue.prototype.$formatVariantPrice = function(variant) {
            var price = variant.calculated_price_incl_tax
            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price / 100)
        }
        
        Vue.prototype.$formatRawPrice = function(cents) {
            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(cents / 100)
        }

        Vue.prototype.$getPaymentLabel = function(providerId) {
            switch(providerId){
                case 'paypal':
                    return 'PayPal'
                case 'manual':
                    return 'Vorkasse'
            }
        }
    }
}
