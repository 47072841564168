<template>
    <div class="items" :class="{dark: $store.state.settings.darkMode}">
        <div
            v-for="item in $store.state.cart.items"
            :key="item.id"
            class="item"
            :class="{'compact': compact}"
        >
            <router-link
                class="thumb"
                :to="`/shop/product/${item.variant.product.handle}`"
                @click.native="$store.commit('closeCart')"
            >
                <img :src="item.thumbnail"/>
            </router-link>
            <div class="title">
                {{ item.title }} ({{ item.variant.title }})
            </div>
            <div class="qty">
                <ApiClickable class="minus" @click="decQty(item)">
                    -
                </ApiClickable>
                <ApiReactable class="current">
                    {{ item.quantity }}
                </ApiReactable>
                <ApiClickable class="plus" @click="incQty(item)">
                    +
                </ApiClickable>
            </div>
            <ApiReactable class="totals">
                {{ $formatRawPrice(item.total) }}
            </ApiReactable>
            <ApiClickable class="delete" @click="deleteItem(item)">
                <img src="@/assets/trash.svg">
            </ApiClickable>
        </div>
    </div>
</template>

<script>
    import ApiClickable from '@/components/ApiClickable'
    import ApiReactable from '@/components/ApiReactable'

    export default {
        name: 'CartItemList',

        props: [
            'compact'
        ],

        components: {
            ApiClickable,
            ApiReactable
        },

        methods: {
            incQty(item) {
                this.$store.dispatch('updateCartItem', {
                    id: item.id,
                    qty: item.quantity + 1
                })
            },

            decQty(item) {
                if(item.quantity > 1){
                    this.$store.dispatch('updateCartItem', {
                        id: item.id,
                        qty: item.quantity - 1
                    })
                }
                else {
                    this.$store.dispatch('deleteCartItem', {
                        id: item.id
                    })
                }
            },

            deleteItem(item) {
                this.$store.dispatch('deleteCartItem', {
                    title: item.variant.title,
                    id: item.id
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .items {
        overflow-y: scroll;
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 10px;

        @include breakpoint(mobile) {
            grid-row-gap: 40px;
            padding: 20px;
        }

        @mixin compactStyle {
            grid-template-columns: .8fr .8fr 2fr 1fr .6fr;
            grid-template-rows: 1fr 1fr;

            .thumb {
                grid-column-start: 1;
                grid-column-end: span 2;
                grid-row-start: 1;
                grid-row-end: span 2;
            }

            .title {
                grid-column-start: 3;
                grid-column-end: span 3;
                grid-row-start: 1;
                grid-row-end: span 1;
                padding-bottom: 5px;
            }

            .qty {
                grid-column-start: 3;
                grid-column-end: span 1;
                grid-row-start: 2;
                grid-row-end: span 1;
                align-self: flex-start;
                justify-content: flex-start;
                padding-left: 20px;
            }

            .totals {
                grid-column-start: 4;
                grid-column-end: span 1;
                grid-row-start: 2;
                grid-row-end: span 1;
                align-self: flex-start;
                justify-content: flex-start;
                padding-left: 5px;

                @include breakpoint(mobile) {
                    justify-content: center;
                }
            }

            .delete {
                grid-column-start: 5;
                grid-column-end: span 1;
                grid-row-start: 2;
                grid-row-end: span 1;
                align-self: flex-start;
                justify-content: flex-end;
            }

            &:last-child {
                border-bottom: none;
            }
        }

        .item {
            display: grid;
            grid-template-columns: 3fr 6fr 2fr 2fr 1fr;
            align-items: center;
            flex-grow: 1;

            &.compact {
                @include compactStyle
            }

            @include breakpoint(mobile) {
                @include compactStyle
            }

            .thumb {
                img {
                    width: 100%;
                    object-fit: cover;
                }
            }

            .title {
                @include fontSize(xs);
                font-weight: 700;
                padding: 0 20px;
                flex-basis: 30%;
                flex-grow: 1;
                white-space: normal;

                a {
                    text-decoration: none;
                }
            }

            .qty {
                display: flex;
                justify-content: center;
                align-items: center;

                .current {
                    @include fontSize(sm);
                    font-family: $accentFont;
                    font-weight: 700;
                    margin: 0 6px;
                    width: 30px;
                    text-align: center;
                }

                .plus,
                .minus {
                    @include fontSize(sm);
                    background: black;
                    color: white;
                    line-height: 24px;
                    height: 28px;
                    width: 28px;
                    text-align: center;
                    border-radius: 16px;
                    cursor: pointer;
                    @include transition;

                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }

            .totals {
                display: flex;
                justify-content: center;
                @include fontSize(sm);
                font-family: $accentFont;
                font-weight: 700;
            }

            .delete {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                @include transition;

                img {
                    height: 28px;
                }

                &:hover {
                    transform: scale(1.1);
                }
            }
        }

        &.dark {
            .item {
                .qty {
                    .plus,
                    .minus {
                        filter: invert(1);
                    }
                }

                .delete {
                    filter: invert(1);
                }
            }
        }
    }
</style>
