import Vue from 'vue'
import VueRouter from 'vue-router'
import { config } from '@/config'
import { store } from '@/store'

Vue.use(VueRouter)

import Release from '@/views/Release'
import Artist from '@/views/Artist'
//import Product from '@/views/Product'

const PageNotFound = () => import('@/views/PageNotFound')
const Home = () => import('@/views/Home')
const Releases = () => import('@/views/Releases')
const Artists = () => import('@/views/Artists')
//const Shop = () => import('@/views/Shop')
//const Checkout = () => import('@/views/Checkout')
//const OrderSuccess = () => import('@/views/OrderSuccess')
const TextPage = () => import('@/views/TextPage')
const Construction = () => import('@/views/Construction')

import {
    privacyPolicyQuery,
    imprintQuery,
    aboutQuery,
    contactQuery,
    agbQuery,
    cancellationQuery
} from '@/clients/strapi'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },{
        path: '/releases',
        name: 'Releases',
        component: Releases
    },{
        path: '/releases/:url_key',
        name: 'Release',
        component: Release
    },{
        path: '/artists',
        name: 'Artists',
        component: Artists
    },{
        path: '/artists/:url_key',
        name: 'Artist',
        component: Artist
    },{
        path: '/privacy',
        name: 'PrivacyPolicy',
        component: TextPage,
        props: {
            query: privacyPolicyQuery
        }
    },{
        path: '/imprint',
        name: 'Imprint',
        component: TextPage,
        props: {
            query: imprintQuery
        }
    },{
        path: '/about',
        name: 'About',
        component: TextPage,
        props: {
            query: aboutQuery
        }
    },{
        path: '/contact',
        name: 'Contact',
        component: TextPage,
        props: {
            query: contactQuery
        }
    },{
        path: '/agb',
        name: 'AGB',
        component: TextPage,
        props: {
            query: agbQuery
        }
    },{
        path: '/cancellation',
        name: 'Cancellation',
        component: TextPage,
        props: {
            query: cancellationQuery
        }
    }
]

if(config.enableShop){
    routes.push({
        path: '/shop',
        name: 'Shop',
        component: Construction
    })
    routes.push({
        path: '/shop/product/:handle',
        name: 'Product',
        component: Construction
    })
    routes.push({
        path: '/shop/checkout',
        name: 'Checkout',
        component: Construction
    })
    routes.push({
        path: '/shop/checkout/success',
        name: 'OrderSuccess',
        component: Construction
    })
}

routes.push({
    path: "*",
    name: '404',
    component: PageNotFound
})

const router = new VueRouter({
    mode: 'history',
    base: config.baseUrl,
    routes,
    scrollBehavior: (to, from, savedPosition) => new Promise((resolve) => {
        var position = {}
        if(savedPosition){
            position = savedPosition
        }
        else {
            position = {
                x: 0,
                y: 0
            }
        }
        router.app.$root.$once('triggerScroll', () => {
            router.app.$nextTick(() => resolve(position))
        })
    })
})

function commitDirection(routes, to, from) {
    var dir =
        routes.indexOf(to.params.url_key) >
        routes.indexOf(from.params.url_key) ?
        'fw' : 'rev'
    store.commit('setTransition', `swipe-${dir}`)
}

router.beforeEach(function(to, from, next){
    // close modals
    if(config.enableShop){
        store.commit('closeCart')
    }
    store.commit('closeBurgerMenu')
    store.commit('closeSearch')

    // handle transitions
    const swipeRoutes = [
        'Release',
        'Artist'
    ]
    if(
        swipeRoutes.includes(to.name) &&
        swipeRoutes.includes(from.name) &&
        to.name == from.name
    ){
        switch(to.name){
            case 'Release':
                commitDirection(store.state.releaseRoutes, to, from)
                break
            case 'Artist':
                commitDirection(store.state.artistRoutes, to, from)
                break
        }
    }
    else {
        store.commit('setTransition', 'swap')
        store.commit('resetPageColors')
    }

    // handle cookie manager
    const noObstruct = [
        'Imprint',
        'PrivacyPolicy'
    ]
    if(
        !noObstruct.includes(to.name) &&
        !store.state.cookieManagerConfigured
    ){
        store.commit('openCookieManager')
    }
    else {
        store.commit('closeCookieManager')
    }
    next()
})

export default router
