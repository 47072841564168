<template>
    <div
        class="wrapper"
        :class="{'open': $store.state.settingsOpen}"
    >
        <Overlay
            :open="$store.state.settingsOpen"
            @click="$store.commit('closeSettings')"
        />

        <div class="settings" :class="{dark: $store.state.settings.darkMode}">
            <div class="header">
                <div
                    class="close-button"
                    @click="$store.commit('closeSettings')"
                >
                    <img src="@/assets/xmark.svg">
                </div>
                <div class="title">
                    Einstellungen
                </div>
            </div>
            <div class="settings-content">
                <div class="toggles">
                    <div
                        class="toggle-item"
                        v-for="toggle in settings"
                        :key="toggle.code"
                    >
                        <div class="label">
                            {{ toggle.title }}
                        </div>
                        <div v-if="toggle.auto" class="spacer"></div>
                        <SimpleToggle
                            v-if="toggle.auto"
                            v-model="toggle.auto.value"
                            :label="toggle.auto.label"
                            class="auto"
                        />
                        <Toggle
                            v-model="toggle.value"
                            onLabel="Aktiviert"
                            offLabel="Deaktiviert"
                            :disabled="toggle.auto && toggle.auto.value"
                            @click.native="handleAutoToggle(toggle)"
                        />
                    </div>
                </div>
            </div>
            <div class="buttons">
                <button
                    type="button"
                    class="button small save"
                    @click="saveSettings"
                >
                    Speichern
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Overlay from '@/components/Overlay'
    import Toggle from '@/components/Toggle'
    import SimpleToggle from '@/components/SimpleToggle'

    export default {
        name: 'Settings',

        components: {
            Overlay,
            Toggle,
            SimpleToggle
        },

        data() {
            return {
                settings: [{
                    title: 'Animationen',
                    code: 'animations',
                    value: this.$store.state.settings.animations
                },{
                    title: 'Farbige Hintergründe',
                    code: 'pageBackground',
                    value: this.$store.state.settings.pageBackground
                },{
                    title: 'Kleinere Kacheln',
                    code: 'smallItems',
                    value: this.$store.state.settings.smallItems
                },{
                    title: 'Dark-Mode',
                    code: 'darkMode',
                    value: this.$store.state.settings.darkMode,
                    auto: {
                        label: 'Auto',
                        code: 'autoDarkMode',
                        value: this.$store.state.settings.autoDarkMode
                    }
                }]
            }
        },

        methods: {
            saveSettings() {
                var self = this
                this.settings.forEach(function(item){
                    if(item.auto){
                        if(item.auto.value){
                            self.$store.commit('setSettingTrue', item.auto.code)
                        }
                        else {
                            self.$store.commit('setSettingFalse', item.auto.code)
                        }
                    }
                    if(item.value){
                        self.$store.commit('setSettingTrue', item.code)
                    }
                    else {
                        self.$store.commit('setSettingFalse', item.code)
                    }
                })
                this.$store.commit('closeSettings')
            },

            refreshSettings() {
                var self = this
                this.settings.forEach(function(item){
                    item.value = self.$store.state.settings[item.code]
                })
            },

            handleAutoToggle(toggle) {
                if(toggle.auto){
                    toggle.auto.value = false
                }
            }
        },

        mounted() {
            this.$setBodyLock()
        },

        watch: {
            '$store.state.settingsOpen': {
                handler() {
                    this.refreshSettings()
                    this.$setBodyLock()
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        z-index: 990;

        &.open {
            .settings {
                transform: translateY(0);
            }
        }

        .settings {
            transform: translateY(-150vh);
            @include transition;
            width: 450px;
            background: $white;
            padding: 20px;
            display: flex;
            flex-direction: column;
            z-index: 999;
            pointer-events: all;

            @include breakpoint(mobile) {
                width: calc(100% - 40px);
                max-width: 450px;
                max-height: calc(100% - 40px);
            }

            .header {
                width: 100%;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                padding-bottom: 15px;
                border-bottom: 1px solid $light2;

                .title {
                    width: 100%;
                    flex-grow: 1;
                    padding-right: 20px;
                    text-align: center;
                    font-family: $accentFont;
                    @include fontSize(xl);
                    font-weight: 700;
                }

                .close-button {
                    cursor: pointer;
                    @include transition;

                    &:hover {
                        transform: scale(1.1);
                    }

                    img {
                        height: 32px;
                    }
                }
            }

            .settings-content {
                flex-shrink: 1;
                overflow-y: scroll;

                .toggles {

                    .toggle-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid $light2;
                        margin-bottom: 10px;
                        padding-bottom: 10px;

                        .label {
                            @include fontSize(xs);
                            font-weight: 500;
                        }

                        .spacer {
                            flex-grow: 1;
                        }

                        .auto {
                            margin-right: 10px;
                        }
                    }
                }
            }

            .buttons {
                margin-top: 10px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 10px;

                .save {
                    grid-column-start: 1;
                    grid-column-end: span 2;
                    margin-top: 10px;
                }

                .button {
                    width: 100%;
                }
            }

            &.dark {
                background: $dark2;

                .header {
                    .close-button {
                        img {
                            filter: invert(1);
                        }
                    }
                }
            }
        }
    }
</style>
