<template>
    <Grid size="6">
        <GridItem
            v-for="artist in results"
            :key="artist.id"
            :url="`/artists/${artist.url_key}`"
            :image="getImage(artist)"
            :title_one="artist.title"
            :small="true"
        />
    </Grid>
</template>

<script>
    import Grid from '@/components/Grid'
    import GridItem from '@/components/GridItem'

    export default {
        name: 'ArtistResults',

        components: {
            Grid,
            GridItem
        },

        props: ['results'],

        methods: {
            getImage(artist) {
                if(artist.image){
                    if(artist.image.formats && artist.image.formats.small){
                        return this.$getImageUrl(artist.image.formats.small.url)
                    }
                    else {
                        return this.$getImageUrl(artist.image.url)
                    }
                }
                else {
                    return require('@/assets/blank-artist.png')
                }
            }
        }
    }
</script>
