<template>
    <div class="grid-item" :class="classes">
        <router-link :to="url" class="inner-wrapper">
            <div class="rendering-area">
                <LoadingPlaceholder/>
                <img v-if="image" v-lazy="image"/>
            </div>
            <div class="content" v-if="anyTitle">
                <div v-if="title_one" class="line-one">
                    {{ title_one }}
                </div>
                <div v-if="title_two" class="line-two">
                    {{ title_two }}
                </div>
                <div v-if="title_three" class="line-three">
                    {{ title_three }}
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
    import LoadingPlaceholder from '@/components/LoadingPlaceholder'

    export default {
        name: 'GridItem',

        components: {
            LoadingPlaceholder
        },

        props: [
            'url',
            'image',
            'title_one',
            'title_two',
            'title_three',
            'sw',
            'type',
            'small'
        ],

        computed: {
            classes() {
                var classes = {
                    sw: this.sw ? true : false,
                    dark: this.$store.state.settings.darkMode
                }
                if(this.type){
                    classes[this.type] = true
                }
                if(this.small){
                    classes.small = true
                }
                return classes
            },

            anyTitle() {
                return (
                    this.title_one ||
                    this.title_two ||
                    this.title_three
                )
            }
        }
    }
</script>

<style lang="scss" scoped>
    .grid-item {
        width: 100%;
        @include fontSize(xs);

        &.small {
            .content {
                padding: 7px;
            }
        }

        .rendering-area {
            background: transparent;
            height: 0;
            position: relative;
            padding-bottom: 120%;
            width: 100%;

            img {
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                object-fit: cover;
            }
        }

        &.cover {
            .rendering-area {
                padding-bottom: 100%;
            }
        }


        &.sw {
            img {
                @include transition;
                filter: grayscale(1) brightness(1.3);
            }
        }

        .inner-wrapper {
            @include transition;
            display: flex;
            flex-direction: column;
            color: $fontColor;
            text-decoration: none;
        }

        &:hover {
            .inner-wrapper {
                box-shadow: $boxShadowXL;
                transform: scale(1.03);
            }

            &.sw {
                img {
                    filter: grayscale(0);
                }
            }

            img {
                box-shadow: $boxShadowMInvisible;
            }

            .content {
                background: $white;
            }
        }

        .content {
            padding: 10px 15px 15px;
            @include transition;
            flex-grow: 1;
            text-align: center;
        }

        img {
            object-fit: cover;
            width: 100%;
            box-shadow: $boxShadowM;
            @include transition;
        }

        .line-one {
            font-weight: 700;
        }

        .line-two {
            font-weight: 500;
        }

        .line-three {
            @include fontSize(xxs);
            font-weight: 400;
        }

        &.dark {
            &:hover {
                .content {
                    background: rgba(255,255,255,0.075);
                }
            }

            .inner-wrapper {
                color: $light3;
            }
        }
    }
</style>
