<template>
    <div class="bandcamp" :class="{dark: $store.state.settings.darkMode}">
        <LoadingPlaceholder/>
        <iframe
            v-if="$store.state.allowEmbeds.bandcamp"
            ref="iframe"
            class="bandcamp-iframe"
            :src="url"
            seamless
        ></iframe>
        <ForbiddenPlayer v-else embed="bandcamp">
            <p>
                Du hast das Einbinden von Bandcamp Playern auf Stuhrwerk.de verboten. Klicke unten, um diese Einstellung zu ändern.
            </p>
            <p>
                Weitere Informationen zu eingebundenen Inhalten findest du in unserer <router-link to="/privacy" @click.native="$store.commit('closeCookieManager')">Datenschutzerklärung</router-link>.
            </p>
        </ForbiddenPlayer>
    </div>
</template>

<script>
    import ForbiddenPlayer from '@/components/players/ForbiddenPlayer'
    import LoadingPlaceholder from '@/components/LoadingPlaceholder'

    export default {
        name: 'BandcampPlayer',

        components: {
            ForbiddenPlayer,
            LoadingPlaceholder
        },

        props: ['bandcamp_album_id'],

        computed: {
            url() {
                return `https://bandcamp.com/EmbeddedPlayer/album=${this.bandcamp_album_id}/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=true/artwork=none/transparent=true/`
            }
        }
    }
</script>

<style lang="scss" scoped>
    .bandcamp {
        box-shadow: 0 0 60px rgba(0,0,0,0.2);
        width: 100%;
        height: 100%;
        position: relative;

        @include breakpoint(mobile) {
            height: 400px;
        }

        .bandcamp-iframe {
            border: 0;
            width: 100%;
            height: 100%;
            position: relative;
        }

        &.dark {
            .bandcamp-iframe {
                filter: invert(1);
            }
        }
    }
</style>
