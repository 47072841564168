<template>
    <div class="grid" :class="sizeClass">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: 'Grid',

        props: {
            size: {
                default: 3
            }
        },

        computed: {
            sizeClass() {
                return `size-${this.size}`
            }
        }
    }
</script>

<style lang="scss" scoped>
    .grid {
        width: 100%;
        display: grid;
        gap: 40px 40px;

        @include breakpoint(mobile) {
            gap: 20px 20px;
        }

        &.size-1 {
            grid-template-columns: 1fr;
        }

        &.size-2 {
            grid-template-columns: 1fr 1fr;

            @include breakpoint('tablet') {
                grid-template-columns: 1fr;
            }

            @include breakpoint('mobile') {
                grid-template-columns: 1fr;
            }
        }

        &.size-3 {
            grid-template-columns: 1fr 1fr 1fr;

            @include breakpoint('tablet') {
                grid-template-columns: 1fr 1fr;
            }

            @include breakpoint('mobile') {
                grid-template-columns: 1fr;
            }
        }

        &.size-4 {
            grid-template-columns: 1fr 1fr 1fr 1fr;

            @include breakpoint('tablet') {
                grid-template-columns: 1fr 1fr 1fr;
            }

            @include breakpoint('mobile') {
                grid-template-columns: 1fr 1fr;
            }
        }

        &.size-5 {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

            @include breakpoint('tablet') {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            @include breakpoint('mobile') {
                grid-template-columns: 1fr 1fr;
            }
        }

        &.size-6 {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            gap: 20px 20px;

            @include breakpoint('tablet') {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            @include breakpoint('mobile') {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
</style>
