import { MeiliSearch } from 'meilisearch'
import { config } from '@/config'


export const meiliClient = new MeiliSearch({
    host: config.meiliUrl,
    apiKey: config.meiliApiKey
})

export const artistIndex = meiliClient.index('artist')
export const releaseIndex = meiliClient.index('release')
export const eventIndex = meiliClient.index('event')

export const meiliPlugin = {
    install(Vue) {
        Vue.prototype.$meiliClient = meiliClient
        Vue.prototype.$artistIndex = artistIndex
        Vue.prototype.$releaseIndex = releaseIndex
        Vue.prototype.$eventIndex = eventIndex
    }
}
