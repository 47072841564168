<template>
    <TransitionGroup
        name="slide"
        tag="div"
        class="notifications"
        :duration="250"
    >
        <Notification
            v-for="notification in $store.state.notifications"
            :notification="notification"
            :key="notification.id"
            @click="$store.commit('killNotification', notification)"
        />
    </TransitionGroup>
</template>

<script>
    import Notification from '@/components/Notification'

    export default {
        name: 'Notifications',

        components: {
            Notification
        }
    }
</script>

<style lang="scss" scoped>
    .notifications {
        position: fixed;
        top: 100px;
        right: 20px;
        display: grid;
        grid-row-gap: 20px;
        @include transition;
        z-index: 9999;

        @include breakpoint(mobile) {
            top: 48px;
            left: 0;
            right: 0;
            grid-row-gap: 0;
        }
    }

    .slide-enter,
    .slide-leave-to {
        transform: translateX(350px);

        @include breakpoint(mobile) {
            transform: translateX(100vw);
        }
    }
    .slide-leave-active {
        position: absolute;
    }
</style>
