<template>
    <div class="rendering-area" >
        <div class="track-list">
            <div class="header">
                Track-Liste
            </div>
            <div class="sides">
                <div class="side" v-for="(side, i) in trackList" :key="i">
                    <div class="side-title" v-if="side.side">
                        {{ side.side }}
                    </div>
                    <div class="tracks">
                        <div class="track" v-for="(track, n) in side.track" :key="n">
                            <div class="number">
                                {{ track.number ? track.number : '' }}
                            </div>
                            <div class="name">
                                {{ track.title ? track.title : '' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TrackList',

        props: [
            'trackList'
        ]
    }
</script>

<style lang="scss" scoped>
    .rendering-area {
        background: transparent;
        height: 0;
        padding-bottom: 100%;
        position: relative;
        width: 100%;
        box-shadow: $boxShadowM;

        @include breakpoint(mobile) {
            height: unset;
        }
    }

    .track-list {
        background: $white;
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;

        .header {
            font-family: $accentFont;
            @include fontSize(xl);
            padding: 20px;
            border-bottom: 1px solid $light3;
            height: 39px;
            line-height: 39px;
        }

        .sides {
            padding: 20px;
            height: calc(100% - 120px);
            overflow-y: scroll;

            .side {
                margin-bottom: 20px;

                .side-title {
                    @include fontSize(xl);
                    font-family: $accentFont;
                    font-weight: 500;
                }

                .tracks {

                    .track {
                        display: grid;
                        grid-template-columns: 30px 1fr;
                        @include fontSize(xs);
                        padding: 5px;

                        &:nth-child(odd) {
                            background: $light4;
                        }

                        .name {
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
</style>
