<template>
    <div class="burger-menu" :class="{dark: $store.state.settings.darkMode}">

        <div class="icon" v-on:click="$store.commit('openBurgerMenu')">
            <img src="@/assets/menu.svg"/>
        </div>

        <Overlay
            :open="$store.state.burgerMenuOpen"
            @click="$store.commit('closeBurgerMenu')"
        />

        <div class="drawer" :class="{'open': $store.state.burgerMenuOpen}">

            <div class="icon close" v-on:click="$store.commit('closeBurgerMenu')">
                <img src="@/assets/xmark.svg"/>
            </div>

            <div class="drawer-content">
                <MainRouterLinks class="burger-router-links menu"/>
                <hr>
                <FooterRouterLinks class="footer-router-links menu"/>
                <hr>
                <div class="external-burger-links menu">
                    <a target="_blank" href="https://www.youtube.com/stuhrwerk">
                        <img src="@/assets/yt_icon_rgb.png">
                        YouTube
                    </a>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Overlay from '@/components/Overlay'
    import MainRouterLinks from '@/components/MainRouterLinks'
    import FooterRouterLinks from '@/components/FooterRouterLinks'

    export default {
        components: {
            Overlay,
            MainRouterLinks,
            FooterRouterLinks
        },

        mounted() {
            this.$store.commit('closeBurgerMenu')
        },

        watch: {
            '$store.state.burgerMenuOpen': {
                handler() {
                    this.$setBodyLock()
                }
            }
        },
    }
</script>

<style lang="scss">
    .burger-menu {
        display: none;
        z-index: 10;

        @include breakpoint('tablet') {
            display: unset;
        }

        .icon {
            width: 26px;
            height: 30px;
            cursor: pointer;
            opacity: 1;

            @include mobileClick;

            &.close {
                width: 22px;
            }
        }

        .drawer {
            position: fixed;
            top: 0;
            height: 100vh;
            left: calc(-80vw - 40px);
            width: calc(80vw - 40px);
            padding: 10px 20px;
            padding-top: 7px;
            @include transition;
            background: $white;
            z-index: 997;

            &.open {
                left: 0;
                box-shadow: 11px 0px 50px 5px rgba(0,0,0,0.2);
            }

            .drawer-content {
                overflow-y: scroll;
                max-height: calc(100vh - 37px);
                margin-top: 10px;

                .burger-router-links,
                .footer-router-links {
                    display: flex;
                    flex-direction: column;
                    margin-top: 20px;
                    align-items: flex-start;

                    a {
                        text-decoration: none;
                    }

                    .menu-item,
                    a {
                        font-family: $accentFont;
                        color: $light1;
                        @include fontSize(xxl);
                        text-decoration: none;
                        @include transition;
                        margin-bottom: 5px;

                        &:hover {
                            color: black;
                        }

                        @include mobileClick;
                    }

                    .router-link-active {
                        .menu-item {
                            color: black;
                            font-weight: 700;
                        }
                    }
                }

                .footer-router-links {
                    margin-top: 20px;
                }

                .external-burger-links {
                    margin-top: 20px;
                    margin-bottom: 30px;

                    a {
                        font-family: $accentFont;
                        color: $light1;
                        @include fontSize(xxl);
                        text-decoration: none;
                        @include transition;
                        margin-bottom: 5px;
                    }

                    img {
                        height: 32px;
                    }
                }
            }
        }

        &.dark {
            .drawer {
                background: $dark2;

                .drawer-content {
                    .burger-router-links,
                    .footer-router-links {
                        .menu-item,
                        a {
                            color: $light2;

                            &:hover {
                                color: $white;
                            }
                        }

                        .router-link-active {
                            .menu-item {
                                color: $white;
                            }
                        }
                    }
                }
            }

            .icon {
                img {
                    filter: invert(1);
                }
            }
        }
    }
</style>
